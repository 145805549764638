const StorageIDs = {
    Plants: 'TOMS.PLANTS',
    Timeout: 'TOMS.LOGIN.TIMEOUT.END',
    JOB_WEIGHT: 'TOMS.JOB.WEIGHT'
};

const AuthStorageKeys = { 
    ApiKey: 'x-api-key',
    DriverId: 'driver-id'
}

export function SetAuthKeys(apiKey, driverId) { 
    localStorage.setItem(AuthStorageKeys.ApiKey, apiKey); 
    localStorage.setItem(AuthStorageKeys.DriverId, driverId); 
}

export function GetApiKey() { 
    return localStorage.getItem(AuthStorageKeys.ApiKey); 
}

export function SetApiKey(apiKey) { 
    localStorage.setItem(AuthStorageKeys.ApiKey, apiKey); 
}

export function GetDriverId() { 
    return localStorage.getItem(AuthStorageKeys.DriverId);
}

export function SetDriverId(driverId) { 
    localStorage.setItem(AuthStorageKeys.DriverId, driverId);
}

export function RemoveAuthKeys() { 
    return localStorage.removeItem(AuthStorageKeys.ApiKey); 
    return localStorage.removeItem(AuthStorageKeys.DriverId);
}


export function StorePlants(plants) {
    var _plants = [];

    for (var i = 0; i < plants.length; i++) { _plants.push({ 'id': plants[i].value, 'label': plants[i].label }); }

    localStorage.setItem(StorageIDs.Plants, JSON.stringify(_plants));
}

export function GetPlants() {
    var plants = JSON.parse(localStorage.getItem(StorageIDs.Plants));

    return plants;
}

export function StoreLoginTimeOut() {
    var now = new Date();

    var TimeOutEnd = new Date(now.getTime() + (5 * 60 * 1000)).toISOString();

    localStorage.setItem(StorageIDs.Timeout, TimeOutEnd);
}

export function GetLoginTimeOut() {
    var TimeOutEnd = new Date(localStorage.getItem(StorageIDs.Timeout));

    return TimeOutEnd;
}

export function SetJobWeight(Weight, JobId) {
    localStorage.setItem(`${StorageIDs.JOB_WEIGHT}.${JobId}`, Math.trunc(Weight));
}

export function GetJobWeight(JobId) {
    return localStorage.getItem(`${StorageIDs.JOB_WEIGHT}.${JobId}`) ?? false;
}

export function ClearJobWeight(JobId) {
    localStorage.removeItem(`${StorageIDs.JOB_WEIGHT}.${JobId}`);

    return true;
}

export function HandleStorageClear() {
    for (const [k, v] of Object.entries(StorageIDs)) {
        if (StorageIDs[k] != StorageIDs.JOB_WEIGHT) { localStorage.removeItem(StorageIDs[k]); }
    }

    for (var i = 0; i < localStorage.length; i++) {
        var storageItem = localStorage.key(i);

        if (storageItem.includes(StorageIDs.JOB_WEIGHT)) { localStorage.removeItem(storageItem); }
    }
}
import { useEffect, useState } from "react";
import axios from "axios";
import { URLS } from "../../Utils/Urls";
import JobCard from "../../Components/JobCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { handleLogout } from "../../Utils/Shared";
import Logout from "../../Components/Logout";

var executing = false; 

function JobList({ driver, SelectJobFn }) {

    const [jobs, setJobs] = useState(null);
    const [confirmLogout, setConfirmLogout] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(GetJobs, []);

    function GetJobs() {
        if (executing) { return; }
        executing = true; 

        axios.get(`${URLS.JOB_DRIVER}/${driver.code}`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to Retriever Driver Jobs"); }

            setJobs(response.data.data);
        }).catch(err => console.error(err)).finally(() => {executing = false;});
    }

    return (
        <>
            <div className="w-100 slide-in-right d-flex justify-content-center align-items-center">
                <div className="w-100">
                    {(jobs?.length == 0 ?? true) &&
                        <div className="mt-5 py-5 w-85 container rounded bg-light border border-2 border-danger NoJobsGlow">
                            <h3 className="text-center">No Jobs Found</h3>
                            <p className="text-center fst-italic mb-0">Check Again Later...</p>
                            <div className="w-100 d-flex justify-content-center">
                                <FontAwesomeIcon className="mt-2" size="xl" icon={Icons.faTruckFast}/>
                            </div>
                        </div> 
                    }
                    {jobs?.filter(j => j.jobId === driver.activeJobId).map((job, key) => <JobCard job={job} driver={driver} key={key} SelectJobFn={SelectJobFn} />)} {/*  Render Active Job First */}
                    {jobs?.filter(j => j.jobId !== driver.activeJobId).map((job, key) => <JobCard job={job} driver={driver} key={key} SelectJobFn={SelectJobFn} />)} {/*  Render Other Jobs */}
                </div>
            </div>
            
            <Logout />
        </>
    );
}

export default JobList;
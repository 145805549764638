import { useEffect, useState } from "react";
import { Asset, Details, Plant, Site, Type, Types } from "../../Utils/JobDetails";
import { FormUpdate } from "../../Utils/FormUpdate";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { URLS } from "../../Utils/Urls";
import { GetPlants } from "../../Utils/StorageHandler";

var executing = false;

function JobUpdate({ job, driver, PageSelect, UpdateDriver }) {

    const [updateFrm, setUpdateFrm] = useState({});
    const [loading, setLoading] = useState(false);

    const [dropAssets, setDropAssets] = useState(null);
    const [siteAssets, setSiteAssets] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(GetSiteAssets, []);

    function GetSiteAssets() {
        if (executing) return;
        executing = true;

        axios.get(`${URLS.ASSETS}/site/${Site(job)?.Id}`).then(response => {
                if (!response.data.success) { console.error("Failed to Retrieve Site Assets"); }
                else { setSiteAssets(response.data.data); }

                GetAssets();
            }).catch(err => console.error(err))
            .finally(() => { executing = false; })
    }

    function GetAssets() {
        axios.get(`${URLS.ASSETS}?includeHalted=false`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to Get Assets"); }

            setDropAssets(response.data.data);
        }).catch((err) => console.error(err)).finally(() => { executing = false; })
    }

    function UpdateJob(e) {
        e.preventDefault();

        setLoading(true);
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        var details = Details(job);

        var asset = details.Asset?.Id;

        if (updateFrm.CollectingAsset || updateFrm.DroppingAsset) {
            if (updateFrm.JobType == 1) {
                asset = updateFrm.CollectingAsset ?? details.Asset?.Id;
            } else { asset = updateFrm.DroppingAsset ?? details.Asset?.Id; }
        }

        var body = {
            assetId: parseInt(asset), // Updating
            id: details.Id,
            customerId: Site(job)?.CustomerId,
            siteId: details.SiteId,
            haulierId: details.HaulierId,
            plantId: parseInt(updateFrm.Plant ?? details.PlantId), // Updating
            Departure: details.Departure,
            forceWash: Asset(job)?.ForcedWashRequired,
            jobType: parseInt(updateFrm.JobType ?? details.JobType), // Updating
            stateId: Asset(job)?.AssetState,
            vehicleReg: details.Driver?.Reg,
            driverId: details.Driver?.Id,
            productId: 1
        }

        axios.post(`${URLS.JOB}/${body.id}/update`, body).then((response) => {
            if (!response.data.success) { throw new Error("Failed to update job details"); }
        }).catch(err => console.error(err))
            .finally(function () {
                setTimeout(function () {
                    setLoading(false);
                    document.body.style.overflow = 'auto';
                    UpdateDriver(function () {
                        PageSelect('job-list');
                    })
                }, 1500);
            });
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="my-5 container bg-light border shadow rounded w-85 p-3">
                    <h5>Job #{job.jobId}</h5>
                    <p>Update Job Details</p>

                    <form onSubmit={UpdateJob}>
                        <label htmlFor="SiteUpdate" className="ms-1">Site: </label>
                        <input className="form-control mb-2" id="SiteUpdate" defaultValue={`${Site(job)?.Customer?.Name} ${Site(job)?.Name}`} disabled />
                        <label htmlFor="TypeUpdate" className="ms-1">Job Type: </label>
                        <select className="form-control mb-2" id="TypeUpdate"
                            value={updateFrm.JobType ?? Type(job)} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "JobType", e.target.value) }}>
                            {Object.values(Types).map((type, key) => <option key={key} value={key}>{type}</option>)}
                        </select>

                        {((updateFrm.JobType ?? Type(job)) == 0 || (updateFrm.JobType ?? Type(job)) == 2) &&
                            <>
                                <label htmlFor="DropUpdate" className="ms-1">Dropping Asset: </label>
                                <select className="form-control mb-2" id="DropUpdate"
                                    value={updateFrm.DroppingAsset ?? Asset(job)?.Id ?? -1} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "DroppingAsset", e.target.value) }}>
                                    <option disabled value={-1}>Please Select an Asset</option>
                                    {dropAssets != null ? dropAssets.map((asset, key) => <option key={key} value={asset.id}>{asset.name}</option>) : null}
                                </select>
                            </>
                        }
                        {((updateFrm.JobType ?? Type(job)) == 1 || (updateFrm.JobType ?? Type(job)) == 2) &&
                            <>
                                <label htmlFor="CollectUpdate" className="ms-1">Collecting Asset: </label>
                                <select className="form-control mb-2" id="CollectUpdate"
                                    value={(updateFrm.JobType ?? Type(job)) == 2 ? updateFrm.CollectingAsset ?? -1 : updateFrm.CollectingAsset ?? Asset(job).Id} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "CollectingAsset", e.target.value) }}>
                                    <option disabled value={-1}>Please Select an Asset</option>
                                    {siteAssets != null ? siteAssets.map((asset, key) => <option key={key} value={asset.id}>{asset.name}</option>) : null}
                                </select>
                            </>
                        }

                        <label htmlFor="PlantUpdate" className="ms-1">AD Plant: </label>
                        <select className="form-control mb-2" id="PlantUpdate"
                            value={updateFrm.Plant ?? Plant(job).Id} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "Plant", e.target.value) }}>
                            {GetPlants()?.map((plant, key) => <option key={key} value={plant.id}>{plant.label}</option>)}
                        </select>

                        <button type="submit" className="btn btn-warning p-3 w-100 mt-3">Update Job&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faPenToSquare} /></button>
                    </form>
                </div>
            </div>

            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    )
}

export default JobUpdate;
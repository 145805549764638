import { useState } from "react";
import { Asset, GetTypeFromId, Site } from "../../Utils/JobDetails";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { URLS } from "../../Utils/Urls";

function JobAbort({ job, driver, PageSelect, UpdateDriver }) {
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);

    function HandleAbort(e) {
        e.preventDefault();

        setLoading(true);

        var body = { reason: reason }

        axios.post(`${URLS.ABORT}/${driver.code}`, body).then((response) => {
            if (!response.data.success) { throw new Error("Failed to submit abort request"); }
        }).catch(err => console.error(err))
            .finally(() => {
                setTimeout(function () {
                    setLoading(false);
                    document.body.style.overflow = 'auto';
                    UpdateDriver(function () {
                        PageSelect('job-list');
                    })
                }, 1500);
            });
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="my-5 container bg-light border shadow rounded w-85 p-3">
                    {(!job?.isActive ?? true) &&
                        <div className="ps-2 rounded bg-white border shadow">
                            <p className="mx-1 mt-1 mb-0 fs-5"><b>Job: </b>#{job?.jobId}</p>
                            <p className="mx-1 mb-0 fs-5"><b>Site: </b>{Site(job)?.Customer?.Name} {Site(job)?.Name}</p>
                            <p className="mx-1 mb-0 fs-5"><b>Type: </b>{GetTypeFromId(job)}</p>
                            <p className="mx-1 mb-1 fs-5"><b>Asset: </b>{Asset(job)?.Name}</p>
                        </div>
                    }

                    {(job?.isActive ?? false) &&
                        <div className="ps-2 rounded bg-white border shadow">
                            <p className="mx-1 mt-1 mb-0 fs-5"><b>Job: </b>#{job?.jobDetails?.id}</p>
                            <p className="mx-1 mb-0 fs-5"><b>Site: </b>{job?.jobDetails?.site?.customer?.name} {job?.jobDetails?.site?.name}</p>
                            <p className="mx-1 mb-0 fs-5"><b>Type: </b>{GetTypeFromId(job, job?.jobDetails?.jobType)}</p>
                            <p className="mx-1 mb-1 fs-5"><b>Asset: </b>{job?.jobDetails?.asset?.name ?? "N/A"}</p>
                        </div>
                    }

                    <form className="mt-4" onSubmit={HandleAbort}>
                        <label htmlFor="AbortReason" className="ms-1">Reason: </label>
                        <textarea className="form-control mb-2" id="AbortReason" placeholder="Reason for Job abort..." value={reason} onChange={(e) => setReason(e.target.value)} />

                        <button type="submit" className="btn btn-danger p-3 w-100 mt-3">Request Abort&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faEject} /></button>
                    </form>
                </div>
            </div>

            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    );
}

export default JobAbort;
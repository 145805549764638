import { useEffect, useState } from "react";
import { Asset, Details, Plant, Site, Type, Types } from "../../Utils/JobDetails";
import { FormUpdate } from "../../Utils/FormUpdate";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { URLS } from "../../Utils/Urls";
import { GetPlants } from "../../Utils/StorageHandler";

var executing = false;

function JobCheckin({ job, driver, PageSelect, UpdateDriver }) {

    const [updateFrm, setUpdateFrm] = useState({});
    const [loading, setLoading] = useState(false);

    const [dropAssets, setDropAssets] = useState(null);
    const [siteAssets, setSiteAssets] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(GetSiteAssets, []);

    function GetSiteAssets() {

        if (executing) return;
        executing = true;

        setLoading(true);

        axios.get(`${URLS.ASSETS}/site/${Site(job)?.Id}`).then((response) => {
            console.log(response.data.data);
            if (!response.data.success) {
                console.error("Failed to Retrieve Site Assets");
            }

            setSiteAssets(response.data.data);
            GetAssets(response.data.data);

        }).catch(err => console.error(err)).finally(() => { executing = false; })

    }

    function GetAssets() {
        axios.get(`${URLS.ASSETS}?includeHalted=false`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to Get Assets"); }

            setDropAssets(response.data.data);
        }).catch((err) => console.error(err)).finally(() => { executing = false; setLoading(false); })
    }

    function HandleCheckInFinish() {
        setTimeout(function () {
            setLoading(false);
            document.body.style.overflow = 'auto';
            UpdateDriver(function () {
                window.location.reload();
                //PageSelect('job-list'); 
            })
        }, 1500);
    }

    function HandleCheckin(e) {
        e.preventDefault();

        if (!updateFrm.CollectingAsset) { return; }

        var finishedCheckIn = 0;

        setLoading(true);
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        var details = Details(job);

        var asset = details.Asset?.Id;

        if (updateFrm.CollectingAsset || updateFrm.DroppingAsset) {
            if (updateFrm.JobType == 1) {
                asset = updateFrm.CollectingAsset ?? details.Asset?.Id;
            } else { asset = updateFrm.DroppingAsset ?? details.Asset?.Id; }
        }

        var body = {
            assetId: parseInt(asset), // Updating
            id: details.Id,
            customerId: Site(job)?.CustomerId,
            siteId: details.SiteId,
            haulierId: details.HaulierId,
            plantId: parseInt(updateFrm.Plant ?? details.PlantId), // Updating
            Departure: details.Departure,
            forceWash: Asset(job)?.ForcedWashRequired,
            jobType: parseInt(updateFrm.JobType ?? details.JobType), // Updating
            //stateId: Asset(job)?.AssetState,
            vehicleReg: details.Driver?.Reg,
            driverId: details.Driver?.Id,
            productId: 1,
        };

        var exchange_body = {
            assetCollectedId: parseInt(updateFrm.CollectingAsset ?? details.Asset?.Id),
            assetDroppedId: parseInt(updateFrm.DroppingAsset ?? details.Asset?.Id)
        };

        if (Type(job) == 2) {
            axios.post(`${URLS.JOB}/${body.id}/update`, body)
                .then((response) => {

                    if (!response.data.success) {
                        console.error("Failed to update job details");
                    }

                    axios.post(`${URLS.MOBILE_EXCHANGE}/${driver.id}`, exchange_body).then((response) => {

                        if (!response.data.success) { console.error("Failed to post exchange"); }

                        var checkinBody = {
                            driverId: driver.id,
                            customerId: Site(job)?.CustomerId,
                            siteId: details.SiteId,
                            deliveringAssetId: parseInt(updateFrm.DroppingAsset ?? details.Asset?.Id),
                            collectingAssetId: parseInt(updateFrm.CollectingAsset)
                        };

                        axios.post(`${URLS.MOBILE_DRIVER}/checkin`, checkinBody).then((response) => {
                            if (!response.data.success) { throw new Error("Failed to checkin driver"); }

                            axios.patch(`${URLS.ASSETS}/${updateFrm.DroppingAsset ?? details.Asset?.Id}/3`).then((response) => {
                                if (!response.data.success) { throw new Error("Failed to move Asset to 'On Site'"); }

                                axios.patch(`${URLS.ASSETS}/${updateFrm.CollectingAsset}/4`).then((response) => {
                                    if (!response.data.success) { throw new Error("Failed to move Asset to 'Inbound'"); }
                                }).catch(err => console.error(err))
                                    .finally(() => { finishedCheckIn++; if (finishedCheckIn == 5) { HandleCheckInFinish(); } });

                            }).catch(err => console.error(err))
                                .finally(() => { finishedCheckIn++; if (finishedCheckIn == 5) { HandleCheckInFinish(); } });

                        }).catch(err => console.error(err))
                            .finally(() => { finishedCheckIn++; if (finishedCheckIn == 5) { HandleCheckInFinish(); } });

                    }).catch(err => console.error(err))
                        .finally(() => { finishedCheckIn++; if (finishedCheckIn == 5) { HandleCheckInFinish(); } });

                }).catch(err => {

                    console.error(err)


                }).finally(() => {

                    finishedCheckIn++;

                    if (finishedCheckIn == 5) {
                        HandleCheckInFinish();
                    }

                });
        } else {
            axios.post(`${URLS.JOB}/${body.id}/update`, body).then((response) => {
                if (!response.data.success) {
                    console.error("Failed to update job details");
                }

                var checkinBody = {
                    driverId: driver.id,
                    customerId: Site(job)?.CustomerId,
                    siteId: details.SiteId,
                    collectingAssetId: parseInt(updateFrm.CollectingAsset)
                };

                axios.post(`${URLS.MOBILE_DRIVER}/checkin`, checkinBody).then((response) => {
                    if (!response.data.success) { throw new Error("Failed to checkin driver"); }

                    axios.patch(`${URLS.ASSETS}/${updateFrm.CollectingAsset}/4`).then((response) => {
                        if (!response.data.success) { throw new Error("Failed to move Asset to 'Inbound'"); }
                    }).catch(err => console.error(err))
                        .finally(() => HandleCheckInFinish());
                }).catch(err => console.error(err))
            }).catch(err => console.error(err))
    }

}

return (
    <>
        <div className="w-100 d-flex justify-content-center align-items-center slide-in">
            <div className="my-5 container bg-light border shadow rounded w-85 p-3">
                <h5>Job #{job.jobId}</h5>
                <p>Update Job Details</p>

                <form onSubmit={HandleCheckin}>
                    <label htmlFor="SiteUpdate" className="ms-1">Site: </label>
                    <input className="form-control mb-2" id="SiteUpdate" defaultValue={`${Site(job)?.Customer?.Name} ${Site(job)?.Name}`} disabled />

                    {Type(job) != 1 &&
                        <>
                            <label htmlFor="DropUpdate" className="ms-1">Dropping Asset: </label>
                            <select className="form-control mb-2" id="DropUpdate"
                                value={updateFrm.DroppingAsset ?? Asset(job).Id} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "DroppingAsset", e.target.value) }}>
                                <option disabled value={-1}>Please Select an Asset</option>
                                {dropAssets != null ? dropAssets.map((asset, key) => <option key={key} value={asset.id}>{asset.name}</option>) : null}
                            </select>
                        </>
                    }

                    <label htmlFor="CollectUpdate" className="ms-1">Collecting Asset: </label>
                    <select className="form-control mb-2" id="CollectUpdate"
                        value={updateFrm?.CollectingAsset ?? -1} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "CollectingAsset", e.target.value) }}>
                        <option disabled value={-1}>Please Select an Asset</option>
                        {siteAssets != null ? siteAssets.map((asset, key) => <option key={key} value={asset.id}>{asset.name}</option>) : null}
                    </select>

                    <label htmlFor="PlantUpdate" className="ms-1">AD Plant: </label>
                    <select className="form-control mb-2" id="PlantUpdate"
                        value={updateFrm.Plant ?? Plant(job).Id} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "Plant", e.target.value) }}>
                        {GetPlants()?.map((plant, key) => <option key={key} value={plant.id}>{plant.label}</option>)}
                    </select>

                    <button type="submit" className="btn btn-primary p-3 w-100 mt-3">Check-In&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faTruckLoading} /></button>
                </form>
            </div>
        </div>


        {loading &&
            <div className='form-loading'>
                <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
            </div>
        }
    </>
)
}

export default JobCheckin;
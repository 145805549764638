import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from 'react';
import { FormUpdate } from '../Utils/FormUpdate';
import axios from 'axios';
import { URLS } from '../Utils/Urls';
import { GetApiKey, GetDriverId, GetLoginTimeOut, RemoveAuthKeys, SetApiKey, SetAuthKeys, StoreLoginTimeOut } from '../Utils/StorageHandler';

var executing = false; 

function LoginPage({ loginFn }) {

    const [loginForm, setLoginForm] = useState({});
    const [submitDisable, setSubmitDisable] = useState(true);
    const [formLoad, setFormLoad] = useState(false);
    const [error, setError] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [submitVerificationDisable, setSubmitVerificationDisable] = useState(true);
    const [validateError, setValidateError] = useState(false);
    const [countingDown, setCountingDown] = useState(false);
    const [countDown, setCountDown] = useState(0);

    const [smsToken, setSmsToken] = useState(''); 
    const [smsCode, setSMSCode] = useState('');

    useEffect(() => { 
        CheckLoggedIn(); 
    }, []);
    
    useEffect(ValidateForm, [loginForm]);

    function CheckLoggedIn() { 
        const apiKey = GetApiKey();
        const driverId = GetDriverId();
        if (apiKey && driverId) { 
            if (executing) return; 
            executing = true;
            setFormLoad(true);   
            axios.get(`${URLS.DRIVER}/${driverId}`, {headers: {'X-Api-Key': apiKey}})
                .then((response) => {
                    if (!response.data.success) { throw new Error("Failed to get Driver"); }
                    //SetApiKey(response.headers['x-api-key']);
                    configureAxiosInterceptors();
                    loginFn(response.data.data);
                })
                .catch(err => { 
                    console.error(err);
                    RemoveAuthKeys();
                })
                .finally(() => { 
                    setFormLoad(false);
                    executing = false;
                })
        }
    }

    function ValidateForm() {
        if (loginForm.driverCode && loginForm.pinCode) { setSubmitDisable(false); }
        else { setSubmitDisable(true); }
    }

    function HandleLogin(e) {

        e.preventDefault();

        setError(false); 

        var body = {
            driverCode: loginForm.driverCode,
            driverPin: loginForm.pinCode
        }

        if (!body.driverCode) { 
            setError('Please enter your driver code.'); 
            return;
        }
        if (!body.driverPin) { 
            setError('Please enter your driver pin.'); 
            return;
            
        }
        if (!/^\d+$/.test(body.driverCode)) { 
            setError('Driver code must contain numbers only.');
            return;
        }
        if (!/^\d+$/.test(body.driverPin)) { 
            setError('Driver pin must contain numbers only.');
            return;
        }

        setFormLoad(true);

        axios.post(URLS.LOGIN, body)
            .then(res => { 
                if (res.data.success) {
                    if (res.data.data.verify) { 
                        setShowVerify(true);
                        setSmsToken(res.data.data.token);
                        beginCountingDown();
                    }
                    else { 
                        // Logged in
                        SetAuthKeys(res.data.data.apiKey, res.data.data.driver.id);
                        configureAxiosInterceptors();
                        loginFn(res.data.data.driver);
                        setCountingDown(false); 
                    }   
                }
                else {
                    setError(res.data.message); 
                }
            })
            .catch(err => { 
                setError(err.toString());
            })  
            .finally(() => { 
                setFormLoad(false);
            })

    }

    const beginCountingDown = () => { 
        setCountingDown(true); 
        var totalSeconds = 300;
        setCountDown('05:00');
        const interval = setInterval(function() { 
            totalSeconds--;
            if (totalSeconds === 0) {
                setCountingDown(false);
                setCountDown(0);
                clearInterval(interval);
                setError('Code expired. Please try again.');
                setShowVerify(false);
            }
            else {
                setCountDown(totalSeconds);
                formatCountdown(totalSeconds);
            }
        }, 1000);

    }

    const HandleValidateLogin = (e) => { 

        e.preventDefault();

        var data = {
            code: smsCode,
            token: smsToken
        }
        if (!data.code) { 
            setValidateError('Please enter a code.'); 
            return; 
        }

        if (data.code.length !== 4) { 
            setValidateError('Code must be 4-digits'); 
            return;
        }   

        setFormLoad(true); 

        axios.post(URLS.VALIDATE_LOGIN, data)
            .then(res => { 
                if (res.data.success) { 
                    SetAuthKeys(res.data.data.apiKey, res.data.data.driver.id)
                    configureAxiosInterceptors();                        
                    loginFn(res.data.data.driver);
                }
                else { 
                    setValidateError(res.data.message) 
                }
            })
            .catch(err => { 
                setValidateError(err.toString());
            })
            .finally(() => { 
                setFormLoad(false);
            })

    }

    const configureAxiosInterceptors = () => { 
        axios.interceptors.request.eject(); 
        axios.interceptors.response.eject();
        axios.interceptors.request.use(function (config) {
            var apiKey = localStorage.getItem('x-api-key');
            if (apiKey) { 
                config.headers.set('X-Api-Key', apiKey)
            }
            return config;
        });
        // axios.interceptors.response.use(function (response) { 
        //     localStorage.setItem('x-api-key', response.headers['x-api-key']);
        //     return response;
        // })
    }

    const formatCountdown = (totalSeconds) => { 

        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = totalSeconds % 60;

        setCountDown(`${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`)

    }

    return (
        <>
            {formLoad &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }

            <div className="w-100 d-flex justify-content-center">
                <div className='w-75 mt-5 pt-5'>

                    {(error) && 
                        <div className='alert alert-danger'>
                            {error}
                        </div>
                    }
                    {(showVerify) && (
                        <>
                            <div className='alert alert-info'>
                                We have sent a 4-digit code to your mobile - please enter it below once you've received it. The code will expire after 5 minutes.
                            </div>
                            {(validateError) && (
                                <div className='alert alert-danger'>
                                    {validateError}
                                </div>
                            )}
                            <form className="bg-light container rounded border shadow mt-4 p-3" onSubmit={HandleValidateLogin}>
                                <label className="w-100 d-flex justify-content-center mt-2" htmlFor="driverCodeInput"><b>SMS Verification Code:</b></label>
                                <input className="form-control my-1 mt-3" 
                                       placeholder="Enter SMS Code..." 
                                       value={smsCode} id="smsVerificationCode" 
                                       onChange={e => setSMSCode(e.target.value)}
                                       required inputMode='numeric' pattern='[0-9]*' />
                                <button className='btn btn-primary w-100 my-1 mt-4' type='submit' disabled={smsCode.length !== 4 || formLoad}>Login&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faRightToBracket} /></button>
                                {(countingDown) && (
                                    <p style={{textAlign: 'center', fontSize: 'small', marginTop: '15px'}}>{countDown}</p>
                                )}
                            </form>
                        </>
                    )}

                    {(!showVerify) && (
                        <form className="bg-light container rounded border shadow mt-5 p-3" onSubmit={HandleLogin}>
                            <label className="w-100 d-flex justify-content-center mt-2" htmlFor="driverCodeInput"><b>Driver Code</b></label>
                            <input className="form-control my-1" placeholder="Enter Driver Code..." id="driverCodeInput" required inputMode='numeric' pattern='[0-9]*'
                                value={loginForm.driverCode ?? ""}
                                onChange={(e) => FormUpdate(setLoginForm, loginForm, "driverCode", e.target.value)} />
                            <label className="w-100 d-flex justify-content-center mt-3" htmlFor="pinCodeInput"><b>Pin Code</b></label>
                            <input className="form-control my-1" placeholder="Enter Pin Code..." id="pinCodeInput" required type='password' inputMode='numeric' pattern='[0-9]*'
                                value={loginForm.pinCode ?? ""}
                                onChange={(e) => FormUpdate(setLoginForm, loginForm, "pinCode", e.target.value)} />
                            
                            {/* <label className="w-100 d-flex justify-content-center" htmlFor="vehicleRegInput"><b>Vehicle Reg</b></label>
                            <input className="form-control my-1" placeholder="Vehicle Reg..." id="vehicleRegInput" required
                                value={loginForm.vehicleReg ?? ""}
                                onChange={(e) => FormUpdate(setLoginForm, loginForm, "vehicleReg", e.target.value)} /> */}

                            <button className='btn btn-primary w-100 my-1 mt-4' type='submit' disabled={submitDisable || formLoad}>Login&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faRightToBracket} /></button>
                        </form>)
                    }
                </div>
            </div>
        </>
    )
}

export default LoginPage;
import axios from "axios";
import { useEffect, useState } from "react";
import { URLS } from "../../Utils/Urls";
import Logout from "../../Components/Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import RouteCard from "../../Components/RouteCard";

var executing = false;

function RouteList({ driver, SelectRouteFn }) {
    const [loading, setLoading] = useState(false);
    const [routes, setRoutes] = useState([]);

    useEffect(GetRoutes, []);

    function GetRoutes() {
        if (executing) { return; }
        executing = true;
        setLoading(true);

        axios.get(URLS.ROUTES + "/" + driver?.id).then((response) => {
            if (!response.data.success) { throw new Error(response.data.message); }

            setRoutes([...response.data.data]);
        }).catch(err => console.error(err)).finally(() => { executing = false; setLoading(false); });
    }

    return (
        <>
            <div className="w-100 slide-in-right d-flex justify-content-center align-items-center">
                <div className="w-100">
                    {(routes?.length == 0 ?? true) && !loading &&
                        <div className="mt-5 py-5 w-85 container rounded bg-light border border-2 border-danger NoJobsGlow">
                            <h3 className="text-center">No Routes Found</h3>
                            <p className="text-center fst-italic mb-0">Check Again Later...</p>
                            <div className="w-100 d-flex justify-content-center">
                                <FontAwesomeIcon className="mt-2" size="xl" icon={Icons.faRoute} />
                            </div>
                        </div>
                    }
                    {routes.filter(r =>
                        Array.from(r.jobs, (j) => j.id).includes(driver.activeJobId)).map((route, key) =>
                            <RouteCard key={key} route={route} active={true} SelectRouteFn={SelectRouteFn} />)
                    }
                    {routes.filter(r =>
                        !Array.from(r.jobs, (j) => j.id).includes(driver.activeJobId)).map((route, key) =>
                            <RouteCard key={key} route={route} active={false} SelectRouteFn={SelectRouteFn} />)
                    }
                </div>
            </div>

            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }

            <Logout />
        </>
    )
}

export default RouteList;
const BASE = 'https://idrive.reneco.co.uk/api/toms/v1'

export const URLS = {
    MOBILE: `${BASE}/mobile`,
    ASSETS: `${BASE}/mobile/assets`,
    SETUP: `${BASE}/mobile/setup`,
    JOB: `${BASE}/mobile/jobs`,
    JOB_DRIVER: `${BASE}/mobile/jobs`,
    ROUTES: `${BASE}/mobile/routes`,
    JOB_ASSET: `${BASE}/jobs/asset`,
    LOGIN: `${BASE}/mobile/driver/login`,
    VALIDATE_LOGIN: `${BASE}/mobile/driver/login/validate`,
    MOBILE_DRIVER: `${BASE}/mobile/driver`,
    TEST: `${BASE}/mobile/driver/test`,
    MOBILE_EXCHANGE: `${BASE}/mobile/jobs/exchange`,
    DRIVER: `${BASE}/mobile/driver`,
    ABORT: `${BASE}/mobile/jobs/abort`,
    WASTE_TRACKER: `${BASE}/mobile/waste-tracker`
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import axios from "axios";
import { URLS } from "../../Utils/Urls";

function RouteCollectionComplete({ route, driver, PageSelect, UpdateDriver }) {
    const [activeJob, setActiveJob] = useState({
        isActive: false,
        jobDetails: null
    });

    const [siteList, setSiteList] = useState([]);
    const [selectedSite, setSelectedSite] = useState({});
    const [nextSelectedSite, setNextSelectedSite] = useState({});
    const [binInput, setBinInput] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => { GetActiveJob(); SetupForm(); }, []);

    useEffect(() => { setActiveJob({ ...selectedSite.job }); }, [selectedSite]);

    function SetupForm() {
        var _siteList = Array.from([...route?.jobs].filter(j => j.complete == null), (j) => {
            return {
                value: j.site?.id,
                label: `${j.site?.customer?.name} - ${j.site?.name}`,
                job: {
                    isActive: true,
                    jobDetails: { ...j }
                }
            }
        });

        setSiteList(_siteList);
        setSelectedSite(_siteList[0]);
        setNextSelectedSite(_siteList[1]);
    }

    function GetNextSites() {
        var _sites = Array.from([...route?.jobs].filter(j => j.complete == null), (j) => `${j.site?.customer?.name} - ${j.site?.name}`);

        if (_sites == null || _sites.length == 0) { return "N/A" }

        return _sites;
    }

    function GetActiveJob() {
        var _jobs = Array.from([...route?.jobs].filter(j => j.id == driver.activeJobId), (j) => {
            return {
                isActive: true,
                jobDetails: { ...j }
            }
        });

        if (_jobs == null || _jobs.length == 0) {
            setActiveJob({
                isActive: false,
                jobDetails: null
            });
        }

        setActiveJob(_jobs[0]);
    }

    function HandleCompletionCollection() {
        setLoading(true);

        var _bins = binInput;

        if (binInput == null || binInput == '' || parseInt(binInput) !== parseInt(binInput)) {
            _bins = 0;
        }

        var body = {
            routeId: route.id,
            driverId: driver.id,
            job1Id: selectedSite.job.jobDetails.id,
            job2Id: nextSelectedSite.job.jobDetails.id,
            bins: parseInt(_bins)
        }

        axios.post(URLS.ROUTES + "/complete-collection", body).then((response) => {
            if (!response.data.success) { throw new Error("Failed to complete route collection"); }
        }).catch(err => console.error(err))
            .finally(() => {
                setLoading(false);
                document.body.style.overflow = 'auto';
                UpdateDriver(function () {
                    window.location.reload();
                });
            })
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="mt-3 my-5 container bg-light border shadow rounded w-85 pt-3 px-0">
                    <div className="mx-2 mb-3 p-2 rounded bg-white border shadow">
                        <p className="mx-1 mt-1 mb-0 fs-6"><b>Route: </b>#{route.id}</p>
                        <p className="mx-1 mb-0 fs-6"><b>Sites: </b></p>
                        {GetNextSites()?.map((site, key) => (
                            <div key={key} className="d-flex align-items-center text-warning">
                                &nbsp;
                                <FontAwesomeIcon icon={Icons.faCircleMinus} />
                                &nbsp;&nbsp;
                                {site}
                            </div>
                        )) ??
                            <div className="d-flex align-items-center text-success">
                                &nbsp;
                                <FontAwesomeIcon icon={Icons.faCheckCircle} />
                                &nbsp;&nbsp;
                                None
                            </div>
                        }
                        <br />
                        <form>
                            <label className="ms-1"><b>Collected</b> From: </label>
                            <select className="form-control mb-2" value={JSON.stringify(selectedSite)} onChange={(e) => setSelectedSite(JSON.parse(e.target.value))}>
                                {siteList.map((site, key) => (
                                    <option disabled key={key} value={JSON.stringify(site)}>{site?.label}</option>
                                ))}
                            </select>

                            <label className="ms-1"><b>Next</b> Site: </label>
                            <select className="form-control mb-2" value={JSON.stringify(nextSelectedSite)} onChange={(e) => setNextSelectedSite(JSON.parse(e.target.value))}>
                                {siteList.map((site, key) => (
                                    <option disabled={activeJob?.jobDetails?.site?.id == site?.value} key={key} value={JSON.stringify(site)}>{site?.label}</option>
                                ))}
                            </select>

                            <label className="ms-1">Bins Collected: <i>(If applicable)</i></label>
                            <input type="number" className="form-control mb-2" placeholder="Please Enter Bin Quantity" value={binInput} onChange={(e) => setBinInput(e.target.value)} />
                        </form>
                    </div>

                    <div className="px-2">
                        <button type="button" className="w-100 btn-success btn p-2 py-2 my-2" onClick={HandleCompletionCollection}>
                            <h5 className="mb-0">Complete Route Collection&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faFlagCheckered} /></h5>
                            <small>({`${activeJob?.jobDetails?.site?.customer?.name} - ${activeJob?.jobDetails?.site?.name}`})</small>
                        </button>
                    </div>
                </div>
            </div>

            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    )
}

export default RouteCollectionComplete;
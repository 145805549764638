import { Asset, Details, GetCustomerLogo, GetTypeFromId, Plant, Site, Type } from "../../Utils/JobDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import axios from "axios";
import { URLS } from "../../Utils/Urls";

function JobPage({ job, active, MarkCompleteFn, MarkUpdateFn, MarkAbortFn, Page, driver, MarkCheckinFn, MarkStartFn, ViewNotesFn, MarkADCheckinFn }) {
    const [slideDir, setSlideDir] = useState(true);

    const [confirmStart, setConfirmStart] = useState(false);
    const [loading, setLoading] = useState(false);

    const [notes, setNotes] = useState([]);

    useEffect(() => {
        if (Page != null) { if (Page[0] === 'job') { setSlideDir(false); } }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (job == null) { return; }
        CheckNotes();
    }, [job]);

    function GetMapSrc() {
        var lat = Site(job)?.Lat;
        var long = Site(job)?.Lng;

        var url = `https://www.openstreetmap.org/export/embed.html?bbox=${long - 0.003}%2C${lat - 0.003}%2C${long + 0.003}%2C${lat + 0.003}&amp;layer=mapnik&amp;marker=${lat}%2C${long}`;

        return url;
    }

    function CheckNotes() {
        var notes = [...Details(job)?.Notes];
        if (notes?.length == 0) { return; }

        var filtered = [...notes.filter(n => n.DriverVisible)];

        setNotes(filtered);
    }

    return (
        <>
            <div className={`w-100 d-flex justify-content-center align-items-center ${slideDir ? "slide-in" : "slide-in-right"}`}>
                <div className={`mt-3 my-5 container bg-light border shadow rounded w-85 pt-3 px-0 ${active && "ActiveJobGlow"}`}>
                    {active &&
                        <div className="w-100 d-flex justify-content-center mb-3">
                            <div className="badge text-bg-success py-0"><h4 className="mb-0">Active Job</h4></div>
                        </div>
                    }

                    <div className="d-flex">
                        <div className="ms-2 ps-2 rounded bg-white border shadow">
                            <p className="mx-1 mt-1 mb-0 fs-6"><b>Job: </b>#{job.jobId}</p>
                            <p className="mx-1 mb-0 fs-6"><b>Site: </b>{Site(job)?.Customer?.Name} {Site(job)?.Name}</p>
                            <p className="mx-1 mb-0 fs-6"><b>Type: </b>{GetTypeFromId(job)}</p>
                            <p className="mx-1 mb-1 fs-6"><b>Asset: </b>{Asset(job)?.Name}</p>
                        </div>
                        <div className="w-40 px-3 ms-2">
                            <div className="d-flex justify-content-center container bg-white rounded border shadow p-1">
                                {GetCustomerLogo(job)}
                            </div>
                        </div>
                    </div>

                    {(Site(job)?.Lat != null && Site(job)?.Lng != null) &&
                        <div className="w-100">
                            <iframe title="Site map" className="mt-3 w-100" height="350" src={GetMapSrc()} />
                        </div>
                    }

                    <div className="mt-3 p-2">
                        {!active && (driver.activeJobId === 0 || driver.activeJobId === null) &&
                            <>
                                <button type="button" className={`w-100 btn-primary btn btn-round-top d-flex justify-content-center p-2 py-3 my-2`} onClick={MarkStartFn}>
                                    <h5 className="mb-0">Start&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faCirclePlay} /></h5>
                                </button>
                            </>
                        }

                        {(active &&
                            (
                                ((Details(job).Exchange || Type(job) == 0) && Plant(job).Id == 1)
                                ||
                                (Asset(job)?.AssetState == 5)
                                ||
                                (Type(job) == 1 && Asset(job)?.AssetState == 4 && Plant(job).Id == 1) 
                            )
                        ) && <button type="button" className="w-100 btn-success btn btn-round-top text-white d-flex justify-content-center p-2 my-2 py-3"
                            onClick={MarkCompleteFn}>
                                <h5 className="mb-0">Complete&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faFlagCheckered} /></h5></button>}

                        {(active &&
                            (
                                ((Details(job).Exchange && Asset(job)?.AssetState != 5) || (Type(job) == 1 && Asset(job)?.AssetState == 4))
                                &&
                                (Plant(job).Id != 1)
                            )
                        ) && <button type="button" className="w-100 btn-primary btn btn-round-top text-white d-flex justify-content-center p-2 my-2 py-3"
                            onClick={MarkADCheckinFn}>
                                <h5 className="mb-0">AD Plant Checkin&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faIndustry} /></h5></button>}

                        {(active &&
                            (
                                (!Details(job).Exchange && Type(job) == 2)
                                ||
                                (Type(job) == 1 && !([4, 5].includes(parseInt(Asset(job)?.AssetState))))
                            )
                        ) && <button type="button" className="w-100 btn-primary btn btn-round-top text-white d-flex justify-content-center p-2 my-2 py-3"
                            onClick={MarkCheckinFn}>
                                <h5 className="mb-0">Check-In&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faTruckRampBox} /></h5></button>}

                        <button type="button" className={`w-100 btn-warning btn text-white d-flex justify-content-center p-2 py-3 my-2 ${!active ? "btn-round-bottom" : "btn-flat"}`}
                            onClick={MarkUpdateFn}>
                            <h5 className="mb-0">Update&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faPenToSquare} /></h5>
                        </button>

                        {active && <button type="button" className="w-100 btn-danger btn btn-round-bottom text-white d-flex justify-content-center p-2 py-3 my-2"
                            onClick={MarkAbortFn}>
                            <h5 className="mb-0">Request Abort&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faEject} /></h5>
                        </button>}

                        {notes.length != 0 && <button type="button" className="w-100 btn-info btn btn-round-bottom text-white d-flex justify-content-center p-2 py-3 my-2"
                            onClick={ViewNotesFn}>
                            <h5 className="mb-0">Notes&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faMessage} /></h5>
                        </button>}
                    </div>
                </div>
            </div>

            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }

            {/* <div className="mt-3 fixed-bottom">
                {!active && <button type="button" className="w-100 btn-primary btn btn-round-top d-flex justify-content-center p-2 py-3">
                    <h5 className="mb-0">Start <FontAwesomeIcon icon={Icons.faCirclePlay} /></h5></button>}
                {active && <button type="button" className="w-100 btn-success btn btn-round-top text-white d-flex justify-content-center p-2 py-3"
                    onClick={MarkCompleteFn}><h5 className="mb-0">Complete <FontAwesomeIcon icon={Icons.faFlagCheckered} /></h5></button>}
                <button type="button" className={`w-100 btn-warning btn text-white d-flex justify-content-center p-2 py-3 btn-flat`}>
                    <h5 className="mb-0">Update <FontAwesomeIcon icon={Icons.faPenToSquare} /></h5>
                </button>
                {active && <button type="button" className="w-100 btn-danger btn btn-flat text-white d-flex justify-content-center p-2 py-3">
                    <h5 className="mb-0">Request Abort <FontAwesomeIcon icon={Icons.faEject} /></h5>
                </button>}
            </div> */}
        </>
    )
}

export default JobPage;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import axios from "axios";
import { URLS } from "../../Utils/Urls";
import { GetPlants } from "../../Utils/StorageHandler";

function RouteADCheckin({ route, driver, PageSelect, UpdateDriver }) {
    const [activeJob, setActiveJob] = useState({
        isActive: false,
        jobDetails: null
    });

    const [plantList, setPlantList] = useState([]);
    const [selectedPlant, setSelectedPlant] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => { GetActiveJob(); SetupForm(); }, []);

    function SetupForm() { setPlantList(GetPlants()); }

    function GetNextSites() {
        var _sites = Array.from([...route?.jobs].filter(j => j.complete == null), (j) => `${j.site?.customer?.name} - ${j.site?.name}`);

        if (_sites == null || _sites.length == 0) { return "N/A" }

        return _sites;
    }

    function GetActiveJob() {
        var _jobs = Array.from([...route?.jobs].filter(j => j.id == driver.activeJobId), (j) => {
            return {
                isActive: true,
                jobDetails: { ...j }
            }
        });

        if (_jobs == null || _jobs.length == 0) {
            setActiveJob({
                isActive: false,
                jobDetails: null
            });
        }

        setActiveJob(_jobs[0]);
    }

    function HandleCheckin() {
        setLoading(true);

        var body = {
            routeId: route.id,
            driverId: driver.id,
            plantId: selectedPlant.id,
            assetId: activeJob.jobDetails?.assetId
        }

        axios.post(URLS.ROUTES + "/ad-checkin", body).then((response) => {
            if (!response.data.success) { throw new Error("Failed to perform route ad checkin"); }
        }).catch(err => console.error(err))
            .finally(() => {
                setLoading(false);
                document.body.style.overflow = 'auto';
                UpdateDriver(function () {
                    window.location.reload();
                });
            })
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="mt-3 my-5 container bg-light border shadow rounded w-85 pt-3 px-0">
                    <div className="mx-2 mb-3 p-2 rounded bg-white border shadow">
                        <p className="mx-1 mt-1 mb-0 fs-6"><b>Route: </b>#{route.id}</p>
                        <p className="mx-1 mb-0 fs-6"><b>Next Sites: </b></p>
                        {GetNextSites()?.map((site, key) => (
                            <div key={key} className="d-flex align-items-center text-warning">
                                &nbsp;
                                <FontAwesomeIcon icon={Icons.faCircleMinus} />
                                &nbsp;&nbsp;
                                {site}
                            </div>
                        )) ??
                            <div className="d-flex align-items-center text-success">
                                &nbsp;
                                <FontAwesomeIcon icon={Icons.faCheckCircle} />
                                &nbsp;&nbsp;
                                None
                            </div>
                        }
                        <br />
                        <form>
                            <label className="ms-1">AD Plant: </label>
                            <select className="form-control mb-2" value={JSON.stringify(selectedPlant)} onChange={(e) => setSelectedPlant(JSON.parse(e.target.value))}>
                                <option value={JSON.stringify({})} disabled>Select an AD Plant...</option>
                                {plantList.map((plant, key) => (
                                    <option key={key} value={JSON.stringify(plant)}>{plant?.label}</option>
                                ))}
                            </select>
                        </form>
                    </div>

                    <div className="px-2">
                        <button type="button" disabled={(selectedPlant?.label ?? null) == null} className={`w-100 btn-primary btn p-2 py-2 my-2`} onClick={HandleCheckin}>
                            <h5 className="mb-0">Check-In&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faIndustry} /></h5>
                            <small>({`${selectedPlant?.label}`})</small>
                        </button>
                    </div>
                </div>
            </div>

            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    )
}

export default RouteADCheckin;
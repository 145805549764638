import { Details, GetCustomerLogo, GetTypeFromId, Site } from "../Utils/JobDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { WasteTracker } from "../Utils/WasteTrackerHandler";
import { useEffect, useState } from "react";
import { GetJobWeight } from "../Utils/StorageHandler";

var executing = false;

function JobCard({ job, driver, SelectJobFn }) {
    const [trackedWaste, setTrackedWaste] = useState('N/A');
    const [cardKey, setCardKey] = useState(0);

    function HandleGetWaste() {

        if (executing) return;
        executing = true;

        var weight = GetJobWeight(Details(job)?.Id);

        if (!weight) {

            window.addEventListener(`_GotDetailsEvent__${Details(job)?.Id ?? "NO_JOB"}`, (e) => { setTrackedWaste(GetJobWeight(Details(job)?.Id)); }, { once: true });

            if (Details(job)?.Exchange) {
                var Tracker = new WasteTracker(Site(job)?.Id, Details(job)?.Id);

                setTimeout(function () {
                    Tracker.GetDetails();
                    executing = false;
                }, 1500);
            }
        } else {
            setTrackedWaste(weight);
        }
    }

    useEffect(HandleGetWaste, []);
    useEffect(() => { setCardKey(k => k + 1); console.log(trackedWaste) }, [trackedWaste]);

    return (
        <div className={`w-85 container rounded border bg-light my-3 ${driver.activeJobId === job.jobId && "ActiveJobGlow"}`}>
            <div className="p-3 h-100 position-relative">
                {[...Details(job)?.Notes]?.filter(n => n.DriverVisible).length != 0 &&
                    <div className="position-absolute top-0 start-75 rounded-4 bg-warning px-3 translate-middle w-50">
                        Check Notes&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faBell} shake />
                    </div>
                }
                <h4>
                    <div className="d-flex align-items-center">
                        Job: {job.jobId}
                        {driver.activeJobId === job.jobId && <div className="badge text-bg-success ms-1 py-0 px-2"><h4 className="mb-0">Active Job</h4></div>}
                    </div>
                    {Details(job)?.Exchange &&
                        <div className="progress progress-striped w-100 my-3 border bg-white" role="progressbar">
                            <div class="progress-bar progress-bar-striped progress-bar-animated w-50" />
                        </div>
                    }
                </h4>
                {Details(job)?.Exchange && <p className="mb-0"><b>Collected: </b>{Details(job)?.Exchange?.AssetCollected?.Name}</p>}
                {Details(job)?.Exchange && <p className="mb-0" key={cardKey}><b>Estimated Waste (Kg): </b>{trackedWaste}</p>}
                <p className="mb-0"><b>Type: </b>{GetTypeFromId(job)} {Details(job)?.Exchange && "(Asset Dropped)"}</p>
                <p className="mb-0"><b>Site: </b>{Site(job)?.Customer?.Name} {Site(job)?.Name}</p>
                {(Details(job).PlantId) && <p className="mb-0"><b>Plant: </b>{Details(job).Plant?.Name}</p>}
                {(Details(job).AssetId) && <p className="mb-0"><b>Asset: </b>{Details(job).Asset?.Name}</p>}

                <div className="d-flex">
                    <div className="bg-white rounded border shadow p-1 w-25 mt-2 me-3">{GetCustomerLogo(job)}</div>
                    <div className="d-flex ms-5">
                        <button className="btn btn-primary stretched-link align-self-end px-4" type="button" onClick={() => SelectJobFn(job)}>
                            More&nbsp;<FontAwesomeIcon className="ms-2" icon={Icons.faArrowRight} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobCard;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';

function GetSiteIcon(index, length) {
    switch (index) {
        case 0: return Icons.faMapMarkerAlt;
        case length - 1: return Icons.faFlagCheckered;
        default: return Icons.faAnglesDown;
    }
}

function GetSiteComplete(route, site) {
    var jobs = [...route.jobs];

    return jobs?.filter(j => j.siteId == site?.id && j.complete != null).length != 0;
}

function RouteCard({ route, active, SelectRouteFn }) {
    return (
        <>
            <div className={`w-85 container rounded border bg-light my-3 pb-1 ${active && "ActiveJobGlow"} position-relative`}>
                <h4 className="my-1">
                    <div className="d-flex align-items-center justify-content-between">
                        Route: {route.id}
                        {active && <div className="badge text-bg-success ms-1 py-0 px-2"><h4 className="mb-0">Active Route</h4></div>}
                    </div>
                </h4>

                <div>
                    {route?.sites?.map((site, key) => (
                        <div key={key} className={`py-2 row ${key % 2 == 0 ? "bg-subtle" : "bg-light"}`}>
                            <div className={`d-flex align-items-center col-1 ${GetSiteComplete(route, site) ? "text-success" : "text-dark"}`}>
                                <FontAwesomeIcon icon={GetSiteIcon(key, route?.sites?.length)} />
                            </div>
                            <div className="d-flex align-items-center col-9">{key + 1}.&nbsp;{site?.customer?.name} - {site?.name}</div>
                            {GetSiteComplete(route, site) &&
                                <div className="d-flex align-items-center col-1 text-success">
                                    <FontAwesomeIcon size="lg" icon={Icons.faCircleCheck} />
                                </div>
                            }
                        </div>
                    ))}
                </div>

                <div className="d-flex justify-content-center my-2">
                    <button className="w-100 btn btn-primary stretched-link align-self-end px-4" type="button" onClick={() => SelectRouteFn(route)}>
                        More&nbsp;<FontAwesomeIcon className="ms-2" icon={Icons.faArrowRight} />
                    </button>
                </div>
            </div>
        </>
    )
}

export default RouteCard;
function FormatDate(date){
    var _date = new Date(date);

    var formatted = `${_date.getDay()}/${_date.getMonth() + 1}/${_date.getFullYear()} ${_date.getHours()}:${_date.getMinutes()}`;

    return formatted;
}

function NoteCard({ note }) {
    return (
        <>
            <div className="rounded bg-light border text-center shadow p-5 text-dark my-2">
                <b>{note.Content}</b>
                <div className="text-center">
                    <small><i>- {note.User?.FullName}</i></small>
                </div>
                <small><i>{FormatDate(note.Created)}</i></small>
            </div>
        </>
    )
}

export default NoteCard;
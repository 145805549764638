import TescoLogo from "./SiteLogos/tesco.svg";

const parse = (obj) => { return JSON.parse(obj); }

export const Types = {
    0: "Drop Off",
    1: "Collection",
    2: "Exchange",
    3: "Route Collection"
}

export function Asset(job) {
    var details = parse(job.jobDetails);

    return details.Asset;
}

export function Site(job) {
    var details = parse(job.jobDetails);

    return details.Site;
}

export function Type(job) {
    var details = parse(job.jobDetails);

    return details.JobType;
}

export function GetTypeFromId(job, id = false) {
    if (!id) return Types[Type(job)]
    if (id) return Types[id]
}

export function Plant(job) {
    var details = parse(job.jobDetails);

    return details.Plant;
}

export function GetCustomerLogo(job) {
    var customerName = parse(job.jobDetails)?.Site?.Customer?.Name;

    if (customerName === 'Tesco') {
        return <img alt="Tesco" src={TescoLogo} style={{ width: '100%', height: 'auto' }} />
    }
    if (customerName === 'Co-op') {
        return <img alt="Coop" src='https://vision.wykes.uk/images/coop-blue.svg' style={{ width: '100%', height: 'auto' }} />
    }
    if (customerName === 'Lidl') {
        return <img alt="Lidl" src='https://insight.wykes.uk/images/lidl_transparent.png' style={{ width: '100%', height: 'auto' }} />
    }

}

export function Details(job) { return parse(job.jobDetails); }
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { handleLogout } from "../Utils/Shared";

function Logout() {
    const [confirmLogout, setConfirmLogout] = useState(false);

    return (
        <>
            <div style={{ position: 'fixed', bottom: 0, width: '100%', height: '70px', textAlign: 'center', left: 0 }}>
                <button className="btn btn-danger" onClick={() => setConfirmLogout(!confirmLogout)} style={{ width: '100%', position: 'absolute', left: 0, bottom: 0, borderRadius: 0, margin: 0, paddingTop: '13px', paddingBottom: '13px', fontSize: 17 }}>
                    <FontAwesomeIcon icon={Icons.faSignOutAlt} /> Sign Out
                </button>
            </div>

            {confirmLogout &&
                <>
                    <div className='form-loading'>
                        <div className='container p-2 bg-light text-dark rounded border w-90 drop-up'>
                            <p className='text-center'>Are you sure you want to sign out?</p>

                            <div className='d-flex'>
                                <button className='btn btn-danger w-50 mx-2' type='button'
                                    onClick={() => { setConfirmLogout(false); document.body.style.overflow = 'auto'; }}>No</button>
                                <button className='btn btn-success w-50 mx-2' type='button'
                                    onClick={() => { setConfirmLogout(false); handleLogout(); document.body.style.overflow = 'auto'; }}>Yes</button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Logout;
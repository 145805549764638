import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

function Banner({ driver, BackPage, PageSelect }) {
    const [confirmBack, setConfirmBack] = useState(false);
    const [toPage, setToPage] = useState('');

    const ValidateBackPage = (BackPage) => { HandleBackPage((typeof BackPage === "object" && BackPage != null) ? BackPage[0] : BackPage, (BackPage != null) ? BackPage[1] ?? false : BackPage); }

    function HandleBackPage(Page, Confirm) {
        if (Page == null || driver == null) { return; }

        Confirm = typeof Confirm === "boolean" ? Confirm : false;
        if (!Confirm) { PageSelect(Page) }
        else {
            setConfirmBack(true);
            setToPage(Page);
            document.body.style.overflow = 'hidden';
            window.scrollTo(0, 0);
        }
    }

    return (
        <>
            <div className="sticky-top w-100">
                <div className="d-flex align-items-center justify-content-between p-3 bg-primary">
                    <h3 className='mb-0'><FontAwesomeIcon type='button'
                        onClick={() => ValidateBackPage(BackPage)} icon={Icons.faArrowLeft} /></h3>
                    <div className='mb-0 d-flex align-items-center h-100'>
                        {/* <h3 className='mb-0'><FontAwesomeIcon icon={Icons.faCrosshairs} /></h3>&nbsp;<h3 className='mb-0'>Eye Drive</h3> */}
                        <h3 className='mb-0'><FontAwesomeIcon icon={Icons.faCrosshairs} /></h3>&nbsp;<h3 className='mb-0'>iDrive</h3>
                        {/* <h3 className='mb-0'><FontAwesomeIcon icon={Icons.faEye} /></h3>&nbsp;<h3 className='mb-0'>Drive</h3> */}

                    </div>
                    <h3 className='mb-0'><FontAwesomeIcon type='button' icon={Icons.faHome} onClick={() => HandleBackPage('main', (BackPage != null) ? BackPage[1] ?? false : BackPage)} /></h3>
                </div>
                {driver &&
                    <div className='d-flex align-items-center justify-content-center bg-success text-white' style={{minHeight: '47px'}}>
                        <h4 className='mb-0 py-1'>{driver.name}</h4>
                    </div>
                }
            </div>

            {confirmBack &&
                <>
                    <div className='form-loading'>
                        <div className='container p-2 bg-light text-dark rounded border w-90 drop-up'>
                            <p className='text-center'>Are you sure you want to cancel this action?</p>

                            <div className='d-flex'>
                                <button className='btn btn-danger w-50 mx-2' type='button'
                                    onClick={() => { setConfirmBack(false); document.body.style.overflow = 'auto'; }}>No</button>
                                <button className='btn btn-success w-50 mx-2' type='button'
                                    onClick={() => { setConfirmBack(false); PageSelect(toPage); document.body.style.overflow = 'auto'; }}>Yes</button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Banner;
import { useEffect, useState } from "react";
import { Asset, Details, Plant, Site, Type, Types } from "../../Utils/JobDetails";
import { FormUpdate } from "../../Utils/FormUpdate";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { URLS } from "../../Utils/Urls";
import { GetPlants } from "../../Utils/StorageHandler";

function ADCheckin({ job, driver, PageSelect, UpdateDriver }) {

    const [updateFrm, setUpdateFrm] = useState({});
    const [loading, setLoading] = useState(false);

    function HandleCheckInFinish() {
        setTimeout(function () {
            setLoading(false);
            document.body.style.overflow = 'auto';
            UpdateDriver(function () {
                window.location.reload();
                //PageSelect('job-list'); 
            })
        }, 1500);
    }

    function HandleCheckin(e) {
        e.preventDefault();

        setLoading(true);
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        var collectingAsset = Asset(job)?.Id;

        if (Type(job) == 2) { collectingAsset = Details(job)?.Exchange?.AssetCollectedId; }

        axios.patch(`${URLS.ASSETS}/${collectingAsset}/5`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to move to third party"); }
        }).catch(err => console.error(err))
        .finally(() => {
            HandleCheckInFinish();
        })
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="my-5 container bg-light border shadow rounded w-85 p-3">
                    <h5>Job #{job.jobId}</h5>
                    <p>Update Job Details</p>

                    <form onSubmit={HandleCheckin}>
                        <label htmlFor="PlantUpdate" className="ms-1">AD Plant: </label>
                        <select disabled className="form-control mb-2" id="PlantUpdate"
                            value={updateFrm.Plant ?? Plant(job).Id} onChange={(e) => { FormUpdate(setUpdateFrm, updateFrm, "Plant", e.target.value) }}>
                            {GetPlants()?.map((plant, key) => <option key={key} value={plant.id}>{plant.label}</option>)}
                        </select>

                        <button type="submit" className="btn btn-primary p-3 w-100 mt-3">Check-In&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faIndustry} /></button>
                    </form>
                </div>
            </div>


            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    )
}

export default ADCheckin;
import { useEffect, useState } from "react";
import { Asset, Details, Plant, Site, Type, Types } from "../../Utils/JobDetails";
import { FormUpdate } from "../../Utils/FormUpdate";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { URLS } from "../../Utils/Urls";
import { GetPlants } from "../../Utils/StorageHandler";

var executing = false;

function JobComplete({ job, driver, PageSelect, UpdateDriver }) {

    const [confirmFrm, setConfirmFrm] = useState({});
    const [loading, setLoading] = useState(false);

    const [siteAssets, setSiteAssets] = useState(null);
    const [dropAssets, setDropAssets] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(GetSiteAssets, []);

    function GetSiteAssets() {

        if (executing) return;
        executing = true;

        setLoading(true);

        axios.get(`${URLS.ASSETS}/site/${Site(job)?.Id}`).then((response) => {
            if (!response.data.success) {
                console.error("Failed to Retrieve Site Assets");
            }
            else {
                setSiteAssets(response.data.data);
            }
            GetAssets();
        }).catch(err => console.error(err)).finally(() => { executing = false; })

    }

    function GetAssets() {
        axios.get(`${URLS.ASSETS}?includeHalted=false`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to Get Assets"); }

            setDropAssets(response.data.data);
        }).catch((err) => console.error(err)).finally(() => { executing = true; setLoading(false); })
    }

    function HandleJobCompletion(body, asset) {
        var details = Details(job);

        if (Type(job) == 1) {
            axios.patch(`${URLS.JOB}/collection-complete/${job.jobId}`).then((response) => {
                if (!response.data.success) { throw new Error("Failed to complete collection"); }
            }).catch(err => console.error(err))
                .finally(() => {
                    HandleUpdateAndCheckin(body, details, asset);

                    setTimeout(function () {
                        setLoading(false);
                        document.body.style.overflow = 'auto';

                        UpdateDriver(function () {
                            window.location.reload();
                            //PageSelect('job-list'); 
                        })
                    }, 2000)

                });
        } else if (Type(job) == 2) {
            axios.patch(`${URLS.JOB}/exchange-complete/${job.jobId}`).then((response) => {
                if (!response.data.success) { throw new Error("Failed to complete exchange"); }
            }).catch(err => console.error(err))
                .finally(() => {
                    HandleUpdateAndCheckin(body, details, asset);

                    setTimeout(function () {
                        setLoading(false);
                        document.body.style.overflow = 'auto';

                        UpdateDriver(function () {
                            window.location.reload();
                            //PageSelect('job-list'); 
                        })
                    }, 2000)

                });
        }
        else {
            axios.patch(`${URLS.JOB}/${body.id}/complete`)
                .then((response) => {
                    if (!response.data.success) {
                        console.error("Failed to complete job");
                    }

                    axios.patch(`${URLS.MOBILE_DRIVER}/complete/${driver.code}`)
                        .then((response) => {
                            if (!response.data.success) {
                                console.error("Failed to mark job as complete on driver mobile");
                            }
                        }).catch(err =>
                            console.error(err)
                        )
                        .finally(() => {

                            HandleUpdateAndCheckin(body, details, asset);

                            setTimeout(function () {
                                setLoading(false);
                                document.body.style.overflow = 'auto';

                                UpdateDriver(function () {
                                    window.location.reload();
                                    //PageSelect('job-list'); 
                                })
                            }, 2000)

                        });

                }).catch(err =>
                    console.error(err)
                );
        }

    }

    function HandleUpdateAndCheckin(body, details, asset) {

        if (body.jobType == 0 && details.Exchange == null) {

            var checkinBody = {
                driverId: driver.id,
                customerId: Site(job)?.CustomerId,
                siteId: details.SiteId,
                deliveringAssetId: parseInt(asset)
            }

            axios.patch(`${URLS.ASSETS}/${body.assetId}/3`)
                .then((response) => {

                    if (!response.data.success) {
                        console.error("Failed to move Asset to 'On Site'");
                    }

                }).catch(err =>
                    console.error(err)
                ).finally(() => {
                    axios.post(`${URLS.MOBILE_DRIVER}/checkin`, checkinBody).then((response) => {
                        if (!response.data.success) { throw new Error("Failed to checkin driver"); }
                    }).catch(err => console.error(err));
                })

        }

        if (body.jobType == 2) {
            if (Plant(job) == 1) {
                var checkinBody = {
                    driverId: driver.id,
                    customerId: Site(job)?.CustomerId,
                    siteId: details.SiteId,
                    deliveringAssetId: parseInt(confirmFrm.DroppingAsset),
                    collectingAssetId: parseInt(confirmFrm.CollectingAsset)
                }

                axios.post(`${URLS.MOBILE_DRIVER}/checkin`, checkinBody).then((response) => {
                    if (!response.data.success) { throw new Error("Failed to checkin driver"); }
                }).catch(err => console.error(err));
            }
        }

    }

    function CompleteJob(e) {
        e.preventDefault();

        setLoading(true);
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        var details = Details(job);

        var asset = details.Asset?.Id;

        if (confirmFrm.CollectingAsset || confirmFrm.DroppingAsset) {
            if (confirmFrm.JobType == 1) {
                asset = confirmFrm.CollectingAsset ?? details.Asset?.Id;
            } else { asset = confirmFrm.DroppingAsset ?? details.Asset?.Id; }
        }

        var stateId = Asset(job)?.AssetState;
        if (details.Exchange != null) { stateId = 4; }

        var plant = confirmFrm?.Plant ?? Details(job).Plant?.Id
        var jobType = confirmFrm?.JobType ?? Type(job)

        // BODGE - Set to ThirdParty
        if (parseInt(jobType) == 1 || parseInt(jobType) == 2 || details.ExchangeId != null) {
            if (parseInt(plant) != 1) {
                stateId = 5;
            }
        }

        var body = {
            assetId: parseInt(asset), // Updating
            id: details.Id,
            customerId: Site(job)?.CustomerId,
            siteId: details.SiteId,
            haulierId: details.HaulierId,
            plantId: parseInt(confirmFrm.Plant ?? details.PlantId), // Updating
            Departure: details.Departure,
            forceWash: Asset(job)?.ForcedWashRequired,
            jobType: parseInt(confirmFrm.JobType ?? details.JobType), // Updating
            stateId: stateId,
            vehicleReg: details.Driver?.Reg,
            driverId: details.Driver?.Id,
            productId: 1,
            exchangeId: details.ExchangeId
        }

        if (plant != 1 && plant != Details(job).Plant?.Id) {
            axios.post(`${URLS.JOB}/${body.id}/update/${parseInt(asset)}`, body).then((response) => {
                if (!response.data.success) { throw new Error("Failed to update job details"); }
            }).catch(err => console.error(err)).finally(() => {
                setLoading(false);
                document.body.style.overflow = 'auto';

                UpdateDriver(function () {
                    window.location.reload();
                    //PageSelect('job-list'); 
                })
            })
        } else {
            axios.post(`${URLS.JOB}/${body.id}/update/${parseInt(asset)}`, body).then((response) => {
                if (!response.data.success) { throw new Error("Failed to update job details"); }
            }).catch(err => console.error(err))
                .finally(() => { HandleJobCompletion(body, asset); });
        }
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="my-5 container bg-light border shadow rounded w-85 p-3">
                    <h5>Job #{job.jobId}</h5>
                    <p>Confirm Job Details</p>

                    <form onSubmit={CompleteJob}>
                        <label htmlFor="SiteConfirm" className="ms-1">Site: </label>
                        <input className="form-control mb-2" id="SiteConfirm" defaultValue={`${Site(job)?.Customer?.Name} ${Site(job)?.Name}`} disabled />

                        <label htmlFor="TypeConfirm" className="ms-1">Job Type: </label>
                        <select className="form-control mb-2" id="TypeConfirm"
                            value={confirmFrm.JobType ?? Type(job)} onChange={(e) => { FormUpdate(setConfirmFrm, confirmFrm, "JobType", e.target.value) }}>
                            {Object.values(Types).map((type, key) => <option key={key} value={key}>{type}</option>)}
                        </select>

                        {((confirmFrm.JobType ?? Type(job)) == 0 || (confirmFrm.JobType ?? Type(job)) == 2) &&
                            <>
                                <label htmlFor="DropConfirm" className="ms-1">Dropping Asset: </label>
                                <select className="form-control mb-2" id="DropConfirm"
                                    value={confirmFrm.DroppingAsset ?? Asset(job).Id} onChange={(e) => { FormUpdate(setConfirmFrm, confirmFrm, "DroppingAsset", e.target.value) }}>
                                    <option disabled value={-1}>Please Select an Asset</option>
                                    {dropAssets != null ? dropAssets.map((asset, key) => <option key={key} value={asset.id}>{asset.name}</option>) : null}
                                </select>
                            </>
                        }
                        {((confirmFrm.JobType ?? Type(job)) == 1 || (confirmFrm.JobType ?? Type(job)) == 2) &&
                            <>
                                <label htmlFor="CollectConfirm" className="ms-1">Collecting Asset: </label>
                                <select className="form-control mb-2" id="CollectConfirm"
                                    value={(confirmFrm.JobType ?? Type(job)) == 2 ? confirmFrm.CollectingAsset ?? Details(job)?.Exchange?.AssetCollectedId : confirmFrm.CollectingAsset ?? Asset(job).Id} onChange={(e) => { FormUpdate(setConfirmFrm, confirmFrm, "CollectingAsset", e.target.value) }}>
                                    <option disabled value={-1}>Please Select an Asset</option>
                                    {siteAssets != null ? siteAssets.map((asset, key) => <option key={key} value={asset.id}>{asset.name}</option>) : null}
                                </select>
                            </>
                        }

                        {((confirmFrm.JobType ?? Type(job)) != 0 || Details(job).Asset.AssetState == 4) &&
                            <>
                                <label htmlFor="PlantConfirm" className="ms-1">AD Plant: </label>
                                <select className="form-control mb-2" id="PlantConfirm"
                                    value={confirmFrm.Plant ?? Plant(job).Id} onChange={(e) => { FormUpdate(setConfirmFrm, confirmFrm, "Plant", e.target.value) }}>
                                    {GetPlants()?.map((plant, key) => <option key={key} value={plant.id}>{plant.label}</option>)}
                                </select>
                            </>
                        }


                        <button type="submit" className="btn btn-success p-3 w-100 mt-3">
                            {(((confirmFrm.Plant ?? Plant(job).Id) != 1 && (confirmFrm.Plant ?? Plant(job).Id) != Details(job).Plant?.Id)) &&
                                <>
                                    Checkin at AD Plant
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={Icons.faIndustry} />
                                </>
                            }
                            {((confirmFrm.Plant ?? Plant(job).Id) != 1 && Details(job).Asset.AssetState == 5) &&
                                <>
                                    Complete AD Tip
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={Icons.faIndustry} />
                                </>
                            }
                            {(confirmFrm.Plant ?? Plant(job).Id) == 1 && Details(job).Asset.AssetState != 5 &&
                                <>
                                    Complete
                                    &nbsp;&nbsp;
                                    <FontAwesomeIcon icon={Icons.faFlagCheckered} />
                                </>
                            }
                        </button>
                    </form>
                </div>
            </div>


            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    )
}

export default JobComplete;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import axios from "axios";
import { URLS } from "../../Utils/Urls";

function RouteStart({ route, driver, PageSelect, UpdateDriver }) {
    const [activeJob, setActiveJob] = useState({
        isActive: false,
        jobDetails: null
    });

    const [siteList, setSiteList] = useState([]);
    const [selectedSite, setSelectedSite] = useState({});

    const [selectedAsset, setSelectedAsset] = useState('');

    const [formError, setFormError] = useState({ show: false, content: '' });

    const [loading, setLoading] = useState(false);

    useEffect(() => { GetActiveJob(); SetupForm(); }, []);

    useEffect(() => { setActiveJob({ ...selectedSite.job }); }, [selectedSite])

    function SetupForm() {
        var _siteList = Array.from([...route?.jobs].filter(j => j.complete == null), (j) => {
            return {
                value: j.site?.id,
                label: `${j.site?.customer?.name} - ${j.site?.name}`,
                job: {
                    isActive: true,
                    jobDetails: { ...j }
                }
            }
        });

        setSiteList(_siteList);
        setSelectedSite(_siteList[0]);
    }

    function GetNextSites() {
        var _sites = Array.from([...route?.jobs].filter(j => j.complete == null), (j) => `${j.site?.customer?.name} - ${j.site?.name}`);

        if (_sites == null || _sites.length == 0) { return "N/A" }

        return _sites;
    }

    function GetActiveJob() {
        var _jobs = Array.from([...route?.jobs].filter(j => j.id == driver.activeJobId), (j) => {
            return {
                isActive: true,
                jobDetails: { ...j }
            }
        });

        if (_jobs == null || _jobs.length == 0) {
            setActiveJob({
                isActive: false,
                jobDetails: null
            });
        }

        setActiveJob(_jobs[0]);
    }

    function HandleStartError(r) {
        if (r.data.message == 'INVALID ASSET') {
            setFormError({
                show: true,
                content: `Asset with name "${selectedAsset}" is Invalid.\nPlease try again.`
            });
        } else {
            setFormError({
                show: true,
                content: r.data.message
            });
        }

        setLoading(false);
    }

    function HandleRouteStart() {
        setLoading(true);
        setFormError({ show: false, content: '' });

        var body = {
            routeId: route.id,
            driverId: driver.id,
            jobId: activeJob.jobDetails.id,
            assetName: selectedAsset
        }

        axios.post(URLS.ROUTES + "/start", body).then((response) => {
            if (!response.data.success) { HandleStartError(response); }
        }).catch(err => console.error(err))
            .finally(() => {
                setLoading(false);
                if (formError.show == false) {
                    document.body.style.overflow = 'auto';
                    UpdateDriver(function () {
                        window.location.reload();
                    });
                }
            });
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="mt-3 my-5 container bg-light border shadow rounded w-85 pt-3 px-0">
                    <div className="mx-2 mb-3 p-2 rounded bg-white border shadow">
                        <p className="mx-1 mt-1 mb-0 fs-6"><b>Route: </b>#{route.id}</p>
                        <p className="mx-1 mb-0 fs-6"><b>Sites: </b></p>
                        {GetNextSites()?.map((site, key) => (
                            <div key={key} className="d-flex align-items-center text-warning">
                                &nbsp;
                                <FontAwesomeIcon icon={Icons.faCircleMinus} />
                                &nbsp;&nbsp;
                                {site}
                            </div>
                        )) ??
                            <div className="d-flex align-items-center text-success">
                                &nbsp;
                                <FontAwesomeIcon icon={Icons.faCheckCircle} />
                                &nbsp;&nbsp;
                                None
                            </div>
                        }
                        <br />
                        <form>
                            <label className="ms-1">First Site: </label>
                            <select className="form-control mb-2" value={JSON.stringify(selectedSite)} onChange={(e) => setSelectedSite(JSON.parse(e.target.value))}>
                                {siteList.map((site, key) => (
                                    <option key={key} value={JSON.stringify(site)}>{site?.label}</option>
                                ))}
                            </select>

                            <label className="ms-1">Asset: </label>
                            <input className="form-control mb-2" value={selectedAsset} onChange={(e) => setSelectedAsset(e.target.value)} placeholder="Asset name (i.e WF40)"></input>
                        </form>

                        {formError.show && <b className="text-danger mb-0">{formError.content}</b>}
                    </div>

                    <div className="px-2">
                        <button type="button" disabled={selectedAsset === ''} className={`w-100 btn-primary btn p-2 py-2 my-2`} onClick={HandleRouteStart}>
                            <h5 className="mb-0">Start Route&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faPlayCircle} /></h5>
                            <small>({`${activeJob?.jobDetails?.site?.customer?.name} - ${activeJob?.jobDetails?.site?.name}`})</small>
                        </button>
                    </div>
                </div>
            </div>

            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    )
}

export default RouteStart;
import { useEffect, useState } from "react";
import { Details } from "../../Utils/JobDetails";
import NoteCard from "../../Components/NoteCard";

function JobNotes({ job }) {
    const [filteredNotes, setFilteredNotes] = useState([]);

    useEffect(() => {
        var notes = [...Details(job)?.Notes].filter(n => n.DriverVisible);

        setFilteredNotes(notes);
    }, []);

    return (
        <>
            <div className="w-100 mt-3 d-flex justify-content-center align-items-center slide-in">
                <div>
                    {filteredNotes?.map((note, key) => (
                        <NoteCard key={key} note={note} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default JobNotes;
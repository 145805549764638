import { TestDriver } from './TestData/TestDriver';
import './App.css';
import Banner from './Components/Banner';
import JobList from './Pages/Jobs/JobList';
import LoginPage from './Pages/Login';
import { useEffect, useRef, useState } from 'react';
import JobPage from './Pages/Jobs/Job';
import JobComplete from './Pages/Jobs/JobComplete';
import axios from 'axios';
import { URLS } from './Utils/Urls';
import { GetApiKey, GetDriverId, HandleStorageClear, StorePlants } from './Utils/StorageHandler';
import JobUpdate from './Pages/Jobs/JobUpdate';
import JobAbort from './Pages/Jobs/JobAbort';
import JobCheckin from './Pages/Jobs/JobCheckin';
import JobStart from './Pages/Jobs/JobStart';
import JobNotes from './Pages/Jobs/JobNotes';
import ADCheckin from './Pages/Jobs/ADCheckin';
import Main from './Pages/Main';
import RouteList from './Pages/Routes/RouteList';
import Route from './Pages/Routes/Route';
import RouteCheckin from './Pages/Routes/RouteCheckin';
import RouteStart from './Pages/Routes/RouteStart';
import RouteCollectionComplete from './Pages/Routes/RouteCollectionComplete';
import RouteComplete from './Pages/Routes/RouteComplete';
import RouteADCheckin from './Pages/Routes/RouteADCheckin';
import RouteADComplete from './Pages/Routes/RouteADComplete';

const scrollTopConf = { top: 0, left: 0, behavior: "instant" };

var executing = false;
var _loggedIn = false;
var driverInterval = null;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [driver, setDriver] = useState(null);

  const [page, setPage] = useState(null);
  const [backPage, setBackPage] = useState(null);

  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);

  useEffect(() => {
    window.scrollTo({ ...scrollTopConf });

    switch (page) {
      case 'job-list':
      case 'route-list':
        setBackPage('main');
        break;
      case 'job':
        setBackPage('job-list');
        break;
      case 'route':
        setBackPage('route-list');
        break;
      case 'job-checkin':
      case 'job-ad-checkin':
      case 'job-complete':
      case 'job-abort':
      case 'job-start':
      case 'job-update':
        setBackPage(['job', true]);
        break;
      case 'route-start':
      case 'route-checkin':
      case 'route-ad-complete':
      case 'route-collection-complete':
      case 'route-complete':
      case 'route-ad-checkin':
      case 'job-abort-route':
        setBackPage(['route', true]);
        break;
      case 'job-notes':
        setBackPage('job');
        break;
      default: setBackPage(null);
    }
  }, [page]);

  useEffect(() => {
    if (driver) {
      setLoggedIn(true);
      _loggedIn = true;
    }

    if (!driverInterval) { setupDriverInterval(); }

  }, [driver]);

  //useEffect(HandleSetup, []);

  //useInterval(GetDriver, 3000);

  function setupDriverInterval() {

    console.log('+++++ setupDriverInterval() +++++');

    driverInterval = setInterval(function () {

      try {
        if (page === 'job-list' || page === null) {
          //UpdateDriver();
        }
      }
      catch (err) {
        console.error(err);
        console.log('+++++ setupDriverInterval().clearInterval(); +++++');
        clearInterval(driverInterval);
      }

      clearInterval(driverInterval);

    }, 4946);

  }

  function useInterval(cb, _d) {
    const savedCb = useRef();

    useEffect(() => { savedCb.current = cb }, [cb]);

    useEffect(() => {
      function tick() { savedCb.current(); }

      if (_d !== null) {
        let id = setInterval(tick, _d);
        return () => clearInterval(id);
      }
    }, [_d]);
  }

  function UpdateDriver(_cb) {

    console.log('+++++ UpdateDriver() +++++');

    var apiKey = GetApiKey();
    var driverId = GetDriverId();

    if (!apiKey || !driverId) {
      return;
    }

    axios.get(`${URLS.DRIVER}/${driverId}`, { headers: { 'X-Api-Key': apiKey } })
      .then(res => {
        if (!res.data.success) throw new Error('Failed to get Driver');
        setDriver(res.data.data);
        if (_cb && typeof _cb === 'function') _cb();
      })
      .catch(err => {
        console.error(err);
        throw err;
      });

  }

  function HandleSelectJob(job) { setSelectedJob({ ...job }); setPage('job'); }

  function HandleSelectRoute(route) { setSelectedRoute({ ...route }); setPage('route'); }

  function HandleCompleteJob() { setPage('job-complete'); }

  function HandleRouteCollectionComplete() { setPage('route-collection-complete'); }

  function HandleUpdateJob() { setPage('job-update'); }

  function HandleAbortJob(job = selectedJob, route = false) { setSelectedJob(job); setPage(`job-abort${route ? "-route" : ""}`); }

  function HandleJobCheckin() { setPage('job-checkin'); }

  function HandleRouteCheckin() { setPage('route-checkin'); }

  function HandleRouteComplete() { setPage('route-complete'); }

  function HandleRouteADComplete() { setPage('route-ad-complete'); }

  function HandleRouteADCheckin() { setPage('route-ad-checkin'); }

  function HandleADCheckin() { setPage('job-ad-checkin'); }

  function HandleRouteStart() { setPage('route-start'); }

  function HandleJobStart() { setPage('job-start'); }

  function HandleJobNotes() { setPage('job-notes'); }

  function HandleLoginSet(driver) { setDriver(driver); _loggedIn = true; HandleSetup() }

  function HandleSetup() {
    if (executing) return;
    executing = true;

    HandleStorageClear() // Reset Local Storage

    axios.get(URLS.SETUP).then((response) => {
      if (!response.data.success) { throw new Error("Failed to Retrieve TOMS Setup"); }

      StorePlants(response.data.data.plants);
      setPage('main');
    }).catch((err) => console.error(err)).finally(() => executing = false);
  }

  return (
    <>
      <Banner driver={driver} BackPage={backPage} PageSelect={setPage} />

      {!loggedIn && <LoginPage loginFn={HandleLoginSet} />}

      {page === 'main' && <Main PageSelect={setPage} />}

      {page === 'job-list' && <JobList driver={driver} SelectJobFn={HandleSelectJob} />}

      {page === 'route-list' && <RouteList driver={driver} SelectRouteFn={HandleSelectRoute} />}

      {page === 'route' && <Route route={selectedRoute}
        page={backPage}
        driver={driver}
        active={Array.from(selectedRoute.jobs, (j) => j.id).includes(driver.activeJobId)}
        MarkStartFn={HandleRouteStart}
        MarkAbortFn={HandleAbortJob}
        MarkCheckinFn={HandleRouteCheckin}
        MarkCollectionComplete={HandleRouteCollectionComplete}
        MarkCompleteFn={HandleRouteComplete}
        MarkADCheckinFn={HandleRouteADCheckin}
        MarkADCompleteFn={HandleRouteADComplete} />
      }

      {page === 'job' &&
        <JobPage job={selectedJob}
          active={selectedJob.jobId === driver.activeJobId}
          Page={backPage}
          PageSelect={setPage}
          driver={driver}
          MarkCompleteFn={HandleCompleteJob}
          MarkUpdateFn={HandleUpdateJob}
          MarkAbortFn={HandleAbortJob}
          MarkCheckinFn={HandleJobCheckin}
          MarkADCheckinFn={HandleADCheckin}
          MarkStartFn={HandleJobStart}
          UpdateDriver={UpdateDriver}
          ViewNotesFn={HandleJobNotes} />
      }

      {/* Route pages */}
      <>
        {page === 'route-start' && <RouteStart route={selectedRoute} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'route-checkin' && <RouteCheckin route={selectedRoute} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'route-collection-complete' && <RouteCollectionComplete route={selectedRoute} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'route-complete' && <RouteComplete route={selectedRoute} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'route-ad-checkin' && <RouteADCheckin route={selectedRoute} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'route-ad-complete' && <RouteADComplete route={selectedRoute} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}
      </>
      {/* Job Pages */}
      <>
        {page === 'job-start' && <JobStart job={selectedJob} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'job-checkin' && <JobCheckin job={selectedJob} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'job-ad-checkin' && <ADCheckin job={selectedJob} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'job-complete' && <JobComplete job={selectedJob} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'job-update' && <JobUpdate job={selectedJob} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'job-abort' || page === 'job-abort-route' && <JobAbort job={selectedJob} driver={driver} PageSelect={setPage} UpdateDriver={UpdateDriver} />}

        {page === 'job-notes' && <JobNotes job={selectedJob} />}
      </>
    </>
  );
}

export default App;

import { useEffect, useState } from "react";
import { Asset, Details, Plant, Site, Type, Types } from "../../Utils/JobDetails";
import { FormUpdate } from "../../Utils/FormUpdate";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { URLS } from "../../Utils/Urls";
import { GetApiKey, GetPlants } from "../../Utils/StorageHandler";

var executing = false;

function JobStart({ job, driver, PageSelect, UpdateDriver }) {
    const [confirmFrm, setConfirmFrm] = useState({});
    const [loading, setLoading] = useState(false);

    const [siteAssets, setSiteAssets] = useState(null);
    const [dropAssets, setDropAssets] = useState(null);

    const [droppingAssetText, setDroppingAssetText] = useState('');
    const [collectingAssetText, setCollectingAssetText] = useState('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(GetSiteAssets, []);

    function GetSiteAssets() {

        if (executing) return;
        executing = true;

        axios.get(`${URLS.ASSETS}/site/${Site(job)?.Id}`).then((response) => {
            if (response.data.success) {
                setSiteAssets(response.data.data);
            } else {
                console.error('No assets on site');
            }
            GetAssets();
        }).catch(err => console.error(err)).finally(() => { executing = false; })
    }

    function GetAssets() {
        axios.get(`${URLS.ASSETS}?state=0&state=1&state=5&includeHalted=false`).then((response) => {
            if (!response.data.success) { throw new Error("Failed to Get Assets"); }

            setDropAssets(response.data.data);
        }).catch((err) => console.error(err)).finally(() => { executing = false })
    }

    function StartJob(e) {

        e.preventDefault();

        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';

        var details = Details(job);

        var asset = details.Asset?.Id;
        var assetChanged = false;

        var _droppingAsset = droppingAssetText.replace(/\s+/g, '').toLowerCase();
        var _collectingAsset = '';
        if (collectingAssetText) {
            _collectingAsset = collectingAssetText.replace(/\s+/g, '').toLowerCase();
        }

        if (droppingAssetText) {
            var assetExists = false;
            for (var i = 0; i < dropAssets.length; i++) {
                var _asset = dropAssets[i];
                if (`${_asset.name}`.toLowerCase() === _droppingAsset) {
                    confirmFrm.DroppingAsset = _asset.id;
                    assetExists = true;
                }
            }
            if (!assetExists) {
                alert('Invalid asset name. Please try again.');
                setDroppingAssetText('');
                return;
            }
        }
        if (collectingAssetText) {
            var assetExists = false;
            for (var i = 0; i < siteAssets.length; i++) {
                var _asset = siteAssets[i];
                if (`${_asset.name}`.toLowerCase() === _collectingAsset) {
                    assetExists = true;
                    confirmFrm.CollectingAsset = _asset.id;
                }
            }
            if (!assetExists) {
                alert('Asset not found at this site. Please try again.');
                setCollectingAssetText('');
                return;
            }
        }

        setLoading(true);

        if (confirmFrm.CollectingAsset || confirmFrm.DroppingAsset) {
            if (parseInt(confirmFrm.JobType ?? details.JobType) == 1) {
                asset = confirmFrm.CollectingAsset ?? details.Asset?.Id;
            } else { asset = confirmFrm.DroppingAsset ?? details.Asset?.Id; }
        }

        if (asset != details.Asset?.Id) { assetChanged = true; }

        var body = {
            assetId: parseInt(asset), // Updating
            id: details.Id,
            customerId: Site(job)?.CustomerId,
            siteId: details.SiteId,
            haulierId: details.HaulierId,
            plantId: parseInt(confirmFrm.Plant ?? details.PlantId), // Updating
            Departure: details.Departure,
            forceWash: Asset(job)?.ForcedWashRequired,
            jobType: parseInt(confirmFrm.JobType ?? details.JobType), // Updating
            stateId: Asset(job)?.AssetState,
            vehicleReg: details.Driver?.Reg,
            driverId: details.Driver?.Id,
            productId: 1,
        }

        var jobType = parseInt(confirmFrm.JobType ?? details.JobType);

        axios.post(`${URLS.JOB}/${body.id}/update`, body)
            .then((response) => {

                if (!response.data.success) {
                    console.error("Failed to update job details");
                }

                axios.post(`${URLS.JOB_DRIVER}/${driver.code}/${job.jobId}`)
                    .then((response) => {

                        if (!response.data.success) {
                            console.error("Failed to mark job as active");
                        }

                        if (!assetChanged && jobType != 1) {
                            axios.patch(`${URLS.ASSETS}/${asset}/1`)
                                .then((response) => {
                                    if (!response.data.success) {
                                        console.error("Failed to move Asset");
                                    }
                                }).catch(err =>
                                    console.error(err)
                                )
                                .finally(() => {
                                    setLoading(false); PageSelect('job-list');
                                });

                        } else if (details.Asset?.Id) {

                            axios.patch(`${URLS.ASSETS}/${details.Asset?.Id}/0`)
                                .then((response) => {
                                    if (!response.data.success) {
                                        console.error("Failed to move Asset to 'Available'");
                                    }
                                }).catch(err =>
                                    console.error(err)
                                )
                                .finally(() => {
                                    if (jobType != 1) {
                                        axios.patch(`${URLS.ASSETS}/${asset}/1`)
                                            .then((response) => {
                                                if (!response.data.success) {
                                                    console.error("Failed to move Asset to 'Scheduled'");
                                                }
                                            }).catch(err =>
                                                console.error(err)
                                            )
                                            .finally(() => {

                                                var assignBody = {
                                                    jobId: details.id,
                                                    assetId: asset,
                                                    forceWash: details.forceWash,
                                                    departing: false
                                                }

                                                axios.patch(`${URLS.ASSETS}/${details.id}/asset/${asset}`, assignBody)
                                                    .then((response) => {
                                                        if (!response.data.success) {
                                                            console.error("Failed to assign Asset");
                                                        }
                                                    }).catch(err =>
                                                        console.error(err)
                                                    )
                                                    .finally(() => {
                                                        setLoading(false);
                                                        UpdateDriver(function () {
                                                            PageSelect('job-list');
                                                        })
                                                    });
                                            });
                                    } else {
                                        setLoading(false);
                                        UpdateDriver(function () {
                                            PageSelect('job-list');
                                        })
                                    }
                                })
                        } else {

                            var assignBody = {
                                jobId: body.id,
                                assetId: asset,
                                forceWash: body.forceWash,
                                departing: false
                            }

                            axios.patch(`${URLS.JOB}/${body.id}/asset/${asset}`, assignBody)
                                .then((response) => {
                                    if (!response.data.success) {
                                        console.error("Failed to assign Asset");
                                    }
                                }).catch(err =>
                                    console.error(err)
                                )
                                .finally(() => {
                                    if (jobType != 1) {
                                        axios.patch(`${URLS.ASSETS}/${asset}/1`)
                                            .then((response) => {
                                                if (!response.data.success) {
                                                    console.error("Failed to move Asset to 'Scheduled'");
                                                }
                                            }).catch(err =>
                                                console.error(err)
                                            )
                                            .finally(() => {
                                                setLoading(false);
                                                UpdateDriver(function () {
                                                    PageSelect('job-list');
                                                })
                                            });
                                    } else {
                                        setLoading(false);
                                        UpdateDriver(function () {
                                            PageSelect('job-list');
                                        })
                                    }
                                });
                        }

                    }).catch(err =>
                        console.error(err)
                    )

            }).catch(err =>
                console.error(err)
            );


    }


    return (
        <>
            <div className="w-100 d-flex justify-content-center align-items-center slide-in">
                <div className="my-5 container bg-light border shadow rounded w-85 p-3">
                    <h5>Job #{job.jobId}</h5>
                    <p>Confirm Job Details</p>

                    <form onSubmit={StartJob}>
                        <label htmlFor="SiteConfirm" className="ms-1">Site: </label>
                        <input className="form-control mb-2" id="SiteConfirm" defaultValue={`${Site(job)?.Customer?.Name} ${Site(job)?.Name}`} disabled />

                        <label htmlFor="TypeConfirm" className="ms-1">Job Type: </label>
                        <select className="form-control mb-2" id="TypeConfirm"
                            disabled
                            value={confirmFrm.JobType ?? Type(job)} onChange={(e) => { FormUpdate(setConfirmFrm, confirmFrm, "JobType", e.target.value) }}>
                            {Object.values(Types).map((type, key) => <option key={key} value={key}>{type}</option>)}
                        </select>

                        {((confirmFrm.JobType ?? Type(job)) == 0 || (confirmFrm.JobType ?? Type(job)) == 2) &&
                            <>
                                <label htmlFor="DropConfirm" className="ms-1">Dropping Asset: </label>
                                <input className="form-control mb-2" id="DropConfirm" onChange={e => setDroppingAssetText(e.target.value)} value={droppingAssetText} placeholder='Asset name (ie WF40)' required />
                            </>
                        }
                        {((confirmFrm.JobType ?? Type(job)) == 1) &&
                            <>
                                <label htmlFor="CollectConfirm" className="ms-1">Collecting Asset: </label>
                                <select className="form-control mb-2" id="CollectConfirm" required
                                    value={(confirmFrm.JobType ?? Type(job)) == 2 ? confirmFrm.CollectingAsset ?? -1 : confirmFrm.CollectingAsset ?? Asset(job)?.Id ?? -1} onChange={(e) => { FormUpdate(setConfirmFrm, confirmFrm, "CollectingAsset", e.target.value) }}>
                                    <option disabled value={-1}>Please Select an Asset</option>
                                    {siteAssets != null ? siteAssets.map((asset, key) => <option key={key} value={asset.id}>{asset.name}</option>) : null}
                                </select>
                            </>
                        }

                        {(Type(job) === 1 || Type(job) === 2) && (
                            <>
                                <label htmlFor="PlantConfirm" className="ms-1">AD Plant: </label>
                                <select className="form-control mb-2" id="PlantConfirm"
                                    value={confirmFrm.Plant ?? Plant(job).Id} onChange={(e) => { FormUpdate(setConfirmFrm, confirmFrm, "Plant", e.target.value) }}>
                                    {GetPlants()?.map((plant, key) => <option key={key} value={plant.id}>{plant.label}</option>)}
                                </select>
                            </>
                        )}


                        <button type="submit" className="btn btn-primary p-3 w-100 mt-3">Start Job&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faFlagCheckered} /></button>
                    </form>
                </div>
            </div>


            {loading &&
                <div className='form-loading'>
                    <FontAwesomeIcon className='w-25 h-25' icon={Icons.faSpinner} spin />
                </div>
            }
        </>
    )
}

export default JobStart;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from "react";
import axios from "axios";
import { URLS } from "../../Utils/Urls";

function Route({ Page, route, active, driver, MarkAbortFn, MarkCheckinFn, MarkStartFn, MarkCollectionComplete, MarkCompleteFn, MarkADCheckinFn, MarkADCompleteFn }) {
    const [slideDir, setSlideDir] = useState(true);
    const [activeJob, setActiveJob] = useState({
        isActive: false,
        jobDetails: null
    });

    useEffect(() => {
        if (Page != null) { if (Page[0] === 'route') { setSlideDir(false); } }

        GetActiveJob();
    }, []);

    function GetNextSites() {
        var _sites = Array.from([...route?.jobs].filter(j => j.complete == null), (j) => `${j.site?.customer?.name} - ${j.site?.name}`);

        if (_sites == null || _sites.length == 0) { return "N/A" }

        return _sites;
    }

    function GetCompletedSites() {
        var _sites = Array.from([...route?.jobs].filter(j => j.complete != null), (j) => `${j.site?.customer?.name} - ${j.site?.name}`);

        if (_sites == null || _sites.length == 0) { return null }

        return _sites;
    }

    function GetActiveJob() {
        var _jobs = Array.from([...route?.jobs].filter(j => j.id == driver.activeJobId), (j) => {
            return {
                isActive: true,
                jobDetails: { ...j }
            }
        });

        if (_jobs == null || _jobs.length == 0) {
            setActiveJob({
                isActive: false,
                jobDetails: null
            });
        }

        setActiveJob(_jobs[0]);
    }

    return (
        <>
            <div className={`w-100 d-flex justify-content-center align-items-center ${slideDir ? "slide-in" : "slide-in-right"}`}>
                <div className={`mt-3 my-5 container bg-light border shadow rounded w-85 pt-3 px-0 ${active && "ActiveJobGlow"}`}>
                    {active &&
                        <div className="w-100 d-flex justify-content-center mb-3">
                            <div className="badge text-bg-success py-0"><h4 className="mb-0">Active Route</h4></div>
                        </div>
                    }

                    <div className="mx-2 mb-3 p-2 rounded bg-white border shadow">
                        <p className="mx-1 mt-1 mb-0 fs-6"><b>Route: </b>#{route.id}</p>
                        <p className="mx-1 mb-0 fs-6"><b>Completed Sites: </b></p>
                        {GetCompletedSites()?.map((site, key) => (
                            <div key={key} className="d-flex align-items-center text-success">
                                &nbsp;
                                <FontAwesomeIcon size="lg" icon={Icons.faCheckCircle} />
                                &nbsp;&nbsp;
                                {site}
                            </div>
                        )) ??
                            <div className="d-flex align-items-center text-danger">
                                &nbsp;
                                <FontAwesomeIcon size="lg" icon={Icons.faCircleXmark} />
                                &nbsp;&nbsp;
                                None
                            </div>
                        }
                        <br />
                        <p className="mx-1 mb-0 fs-6"><b>Next Sites: </b></p>
                        {GetNextSites()?.map((site, key) => (
                            <div key={key} className="d-flex align-items-center text-warning">
                                &nbsp;
                                <FontAwesomeIcon size="lg" icon={Icons.faCircleMinus} />
                                &nbsp;&nbsp;
                                {site}
                            </div>
                        )) ??
                            <div className="d-flex align-items-center text-success">
                                &nbsp;
                                <FontAwesomeIcon icon={Icons.faCheckCircle} />
                                &nbsp;&nbsp;
                                None
                            </div>
                        }
                    </div>

                    <div className="px-2">
                        {!activeJob?.isActive && driver.activeJobId == null &&
                            <button type="button" className={`w-100 btn-primary btn d-flex justify-content-center p-2 py-3 my-2`}
                                onClick={MarkStartFn}>
                                <h5 className="mb-0">Start Route&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faCirclePlay} /></h5>
                            </button>
                        }

                        {activeJob?.isActive &&
                            <>
                                {activeJob?.jobDetails?.asset?.assetState == 2 && (
                                    <button type="button" className={`w-100 btn-primary btn btn-round-top d-flex justify-content-center p-2 py-3 my-2`}
                                        onClick={MarkCheckinFn}>
                                        <h5 className="mb-0">Check-In&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faTruckRampBox} /></h5>
                                    </button>
                                )}

                                {activeJob?.jobDetails?.asset?.assetState == 3 && GetNextSites()?.length != 1 && (
                                    <button type="button" className={`w-100 btn-success btn btn-round-top p-2 py-2 my-2`}
                                        onClick={MarkCollectionComplete}>
                                        <h5 className="mb-0">Complete Route Collection&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faFlagCheckered} /></h5>
                                        <small>({`${activeJob?.jobDetails?.site?.customer?.name} - ${activeJob?.jobDetails?.site?.name}`})</small>
                                    </button>
                                )}

                                {activeJob?.jobDetails?.asset?.assetState == 3 && GetNextSites()?.length == 1 && (
                                    <button type="button" className={`w-100 btn-success btn btn-round-top p-2 py-2 my-2`}
                                        onClick={MarkCompleteFn}>
                                        <h5 className="mb-0">Complete Route&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faFlagCheckered} /></h5>
                                        <small>({`${activeJob?.jobDetails?.site?.customer?.name} - ${activeJob?.jobDetails?.site?.name}`})</small>
                                    </button>
                                )}

                                {activeJob?.jobDetails?.asset?.assetState == 4 && activeJob?.jobDetails?.plantId != 1 && (
                                    <button type="button" className={`w-100 btn-primary btn btn-round-top p-2 py-2 my-2`}
                                        onClick={MarkADCheckinFn}>
                                        <h5 className="mb-0">Check-In at AD Plant&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faIndustry} /></h5>
                                        <small>({`${activeJob?.jobDetails?.plant?.name}`})</small>
                                    </button>
                                )}

                                {activeJob?.jobDetails?.asset?.assetState == 5 && (
                                    <button type="button" className={`w-100 btn-success btn btn-round-top p-2 py-2 my-2`}
                                        onClick={MarkADCompleteFn}>
                                        <h5 className="mb-0">Complete AD Tip&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faFlagCheckered} /></h5>
                                        <small>({`${activeJob?.jobDetails?.site?.customer?.name} - ${activeJob?.jobDetails?.site?.name}`})</small>
                                    </button>
                                )}

                                <button type="button" className="w-100 btn-danger btn btn-round-bottom text-white d-flex justify-content-center p-2 py-3 my-2"
                                    onClick={() => MarkAbortFn(activeJob, true)}>
                                    <h5 className="mb-0">Request Abort&nbsp;&nbsp;<FontAwesomeIcon icon={Icons.faEject} /></h5>
                                </button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Route;
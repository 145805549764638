import axios from "axios";
import { URLS } from "./Urls";
import { ClearJobWeight, SetJobWeight } from "./StorageHandler";

export class WasteTracker {
    SiteData = false;
    Weight;
    TrailerOnSite;
    MatchedNames;

    constructor(SiteId, JobId) {
        this.SiteId = SiteId;
        this.JobId = JobId;

        this.MatchIdToName(this.SiteId);
        ClearJobWeight(this.JobId);
    }

    MatchIdToName(id) {
        axios.get(`${URLS.MOBILE}/site/${id}`).then((response) => {
            if (!response.data.success) { throw new Error(`Failed to get site\n${response.data.message}\n\n${response.data.exception}`) }

            var data = response.data.data;

            var Names = {
                Customer: data.customer?.name,
                Site: data.name
            }

            this.MatchedNames = Names;
        }).catch(err => console.error(err));
    }

    GetDetails() {
        axios.get(URLS.WASTE_TRACKER).then((response) => {
            if (!response.data.success) { throw new Error(`Failed to retrieve Waste Tracker Stats\n${response.data.message}\n\n${response.data.exception}`); }

            var data = response.data.data;

            var filteredData = data.filter(d =>
                (d.siteName).toUpperCase().includes((this.MatchedNames?.Site).toUpperCase()) && d.customerName == this.MatchedNames?.Customer
            );

            this.SiteData = { ...filteredData[0] };
            this.Weight = this.SiteData?.estimatedWeight;
            this.TrailerOnSite = this.SiteData?.trailerOnSite != null;

            SetJobWeight(this.Weight, this.JobId);
        }).catch(err => console.error(err))
        .finally(() => { window.dispatchEvent(new Event(`_GotDetailsEvent__${this.JobId ?? "NO_JOB"}`)); });
    }
}